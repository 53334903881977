import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useLocation } from "@reach/router";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import useContentNavigationTree, {
  NavigationNode,
} from "../../hooks/useContentNavigationTree";
import GatsbyLink from "../GatsbyLink";
import {
  anchorLinkTopId,
  ColorThemeContext,
} from "../../layouts/DefaultLayout";
import Sticky from "../Sticky";
import { contentMaxWidth } from "../../constants/sizes";
import { Top } from "../Icons";
import { navigationBarBreadcrumbs } from "../../constants/z-indices";
import { linkPadding, iconSize } from "./breadcrumbs-sizes";

const StickyContainer = styled(Sticky)`
  max-width: ${contentMaxWidth}px;
  width: 100%;
  display: flex;
  z-index: ${navigationBarBreadcrumbs};
`;

const Link = styled(GatsbyLink)`
  flex: 1;
  display: flex;
  align-items: center;
  padding: ${linkPadding}px;
`;

const Icon = styled(GatsbyImage)`
  max-width: ${iconSize}px;
  max-height: ${iconSize}px;
  margin-right: 10px;
`;

const TopIcon = styled(Top)`
  margin-left: auto;
`;

const TitleContainer = styled.div`
  flex-grow: 1;
  display: flex;
`;

interface BreadcrumbsProps {}

const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = (props) => {
  const colorTheme = React.useContext(ColorThemeContext);
  const buildBreadcrumb = (
    key: string,
    title: string,
    path: string,
    hierarchyLevel: number,
    icon: IGatsbyImageData,
    isTopIconVisible: boolean
  ) => {
    const backgroundColor = colorTheme.colors[hierarchyLevel === 2 ? 3 : 4];
    return (
      <StickyContainer key={key}>
        <Link
          to={path}
          css={css`
            background-color: ${backgroundColor};
          `}
        >
          <TitleContainer>
            <Icon image={icon} alt={title} />
            {title}
          </TitleContainer>
          {isTopIconVisible && <TopIcon />}
        </Link>
      </StickyContainer>
    );
  };

  const contentNavigationTree = useContentNavigationTree();
  const location = useLocation();
  const nodes: NavigationNode[] = [];
  const currentNavigationNode = contentNavigationTree.getNodeByPath(
    location.pathname
  );
  if (currentNavigationNode) {
    const minimumHierarchyLevel = 2;
    for (
      let i = minimumHierarchyLevel;
      i < currentNavigationNode.hierarchyLevel;
      i++
    ) {
      nodes.push(
        contentNavigationTree.getRelatedParentNodeOfHierarchyLevel(
          currentNavigationNode,
          i
        )
      );
    }
    if (currentNavigationNode.hierarchyLevel >= minimumHierarchyLevel) {
      nodes.push(currentNavigationNode);
    }
  }
  const breadcrumbs = nodes.map((node, index) => {
    const isLastBreadcrumb = index === nodes.length - 1;
    return buildBreadcrumb(
      node.key,
      node.title,
      `${node.path}#${anchorLinkTopId}`,
      node.hierarchyLevel,
      node.icon,
      isLastBreadcrumb
    );
  });

  return <>{breadcrumbs}</>;
};

export default Breadcrumbs;
