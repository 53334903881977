import * as React from "react";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import SpeechOutput, {
  PlayButtonProps,
  SpeechOutputProps,
} from "gatsby-mdx-tts/SpeechOutput";
import { UseSoundHookSignature } from "gatsby-mdx-tts/internals/hooks/UseSound";
import { ColorThemeContext } from "../layouts/DefaultLayout";
import { ColorShade } from "../types/color-theme";
import {
  SpeakerOffBlack,
  SpeakerOffWhite,
  SpeakerOnBlack,
  SpeakerOnWhite,
} from "./Icons";
import { IconButton } from "./Clickables";
import ContentElement from "./ContentElement";
import { useStore } from "./StoreProvider";
import useSound from "../hooks/useSound";
import { karlUndKlaraContentBlockColors } from "../constants/color-themes";

const useCustomSoundHook: UseSoundHookSignature = (soundFilePath: string) => {
  const soundFilePaths = React.useMemo(() => [soundFilePath], [soundFilePath]);
  const soundControls = useSound(soundFilePaths);

  const setPlaying = (playing: boolean) => {
    if (!soundControls.has(soundFilePath)) {
      return;
    }
    if (playing) {
      soundControls.get(soundFilePath).playSound();
    } else {
      soundControls.get(soundFilePath).stopSound();
    }
  };

  const isPlaying: boolean =
    soundControls.has(soundFilePath) &&
    soundControls.get(soundFilePath).isSoundPlaying;

  return [isPlaying, setPlaying];
};

const OuterContainer = styled.div``;

const InnerContainer = styled.div<{ contentBlockColors: ContentBlockColors }>`
  background-color: ${(props) => props.contentBlockColors.backgroundColor};
  color: ${(props) => props.contentBlockColors.textColor};

  mark,
  a {
    background-color: ${(props) => props.contentBlockColors.textHighlightColor};
    color: ${(props) => props.contentBlockColors.textColor};
  }
`;

const MainContentContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const PlayButton = styled(IconButton)`
  margin-left: -10px;
`;

export interface ContentBlockColors {
  backgroundColor: string;
  textHighlightColor: string;
  textColor: string;
}

interface ContentBlockBaseProps extends SpeechOutputProps {}

interface ContentBlockProps extends ContentBlockBaseProps {
  className?: string;
  colorShade?: ColorShade;
  customColors?: ContentBlockColors;
  hideSpeechOutputPlayButton?: boolean;
  elementBeforeSpeechOutput?: React.ReactElement;
}

const ContentBlock: React.FunctionComponent<ContentBlockProps> = ({
  className,
  colorShade,
  customColors,
  hideSpeechOutputPlayButton,
  elementBeforeSpeechOutput,
  children,
  ...speechOutputProps
}) => {
  const rootStore = useStore();

  React.useEffect(() => {
    rootStore.contentBlockStore.registerContentBlock(speechOutputProps.id);
    return () =>
      rootStore.contentBlockStore.unregisterContentBlock(speechOutputProps.id);
  }, [rootStore.contentBlockStore, speechOutputProps.id]);

  const contentBlock = rootStore.contentBlockStore.contentBlocks.get(
    speechOutputProps.id
  );

  const colorTheme = React.useContext(ColorThemeContext);
  const contentBlockColors =
    customColors || colorTheme.colorsByShade[colorShade || ColorShade.DARK];

  const CustomPlayButton: React.FunctionComponent<PlayButtonProps> = (
    buttonProps
  ) => {
    const useWhitePlayButton =
      !colorShade ||
      colorShade === ColorShade.DARKEST ||
      colorShade === ColorShade.DARK;
    const PlayIcon = useWhitePlayButton ? SpeakerOnWhite : SpeakerOnBlack;
    const StopIcon = useWhitePlayButton ? SpeakerOffWhite : SpeakerOffBlack;
    return (
      <ContentElement>
        <PlayButton onClick={buttonProps.onClick}>
          {buttonProps.isPlaying ? <StopIcon /> : <PlayIcon />}
        </PlayButton>
      </ContentElement>
    );
  };

  const onWordMarked = (word: string) =>
    contentBlock?.addCurrentlyTriggeredWord(word);

  const contentElements = hideSpeechOutputPlayButton ? (
    children
  ) : (
    <SpeechOutput
      {...speechOutputProps}
      customPlayButton={CustomPlayButton}
      onWordMarked={onWordMarked}
      useCustomSoundHook={useCustomSoundHook}
      ignoredWordSplittingCharactersRegex={/·/}
    >
      {children}
    </SpeechOutput>
  );

  return (
    <OuterContainer id={speechOutputProps.id} className={className}>
      {elementBeforeSpeechOutput}
      <InnerContainer contentBlockColors={contentBlockColors}>
        <MainContentContainer>{contentElements}</MainContentContainer>
      </InnerContainer>
    </OuterContainer>
  );
};

interface KlarUndEinfachContentBlockProps extends ContentBlockBaseProps {}

export const KlarUndEinfachContentBlock: React.FunctionComponent<
  KlarUndEinfachContentBlockProps
> = (props) => (
  <ContentBlock {...props} customColors={karlUndKlaraContentBlockColors} />
);

export default observer(ContentBlock);
