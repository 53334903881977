import React from "react";
import styled from "@emotion/styled";
import isMobile from "../utils/isMobile";

const tabletMinWidth = 768;
const desktopMinWidth = 992;

const getMediaQuery = (minWidth: number) => `@media (min-width: ${minWidth}px)`;

export const tabletAndBigger = getMediaQuery(tabletMinWidth);

export const desktopAndBigger = getMediaQuery(desktopMinWidth);

export const SmallerThanTablet = styled.div`
  ${tabletAndBigger} {
    display: none;
  }
`;

export const TabletAndBigger = styled.div`
  display: none;
  ${tabletAndBigger} {
    display: inline;
  }
`;

export const SmallerThanDesktop = styled.div`
  ${desktopAndBigger} {
    display: none;
  }
`;

export const DesktopAndBigger = styled.div`
  display: none;
  ${desktopAndBigger} {
    display: inline;
  }
`;

export const DesktopOnly = ({ children }) => {
  if (isMobile) {
    return null;
  }
  return children;
};

export const MobileOnly = ({ children }) => {
  if (isMobile) {
    return children;
  }
  return null;
};
