import { ContentBlockColors } from "../components/ContentBlock";

export enum ColorShade {
  DARKEST = "DARKEST",
  DARK = "DARK",
  LIGHT = "LIGHT",
  LIGHTEST = "LIGHTEST",
}

class ColorTheme {
  public colors: string[];

  constructor(...colors: string[]) {
    if (colors.length !== 11) {
      throw new Error("Must provide exact amount of colors per theme");
    }
    this.colors = ["", ...colors];
  }

  public get colorsByShade(): { [key: string]: ContentBlockColors } {
    return {
      [ColorShade.DARKEST]: {
        backgroundColor: this.colors[5],
        textHighlightColor: this.colors[6],
        textColor: "#FFFFFF",
      },
      [ColorShade.DARK]: {
        backgroundColor: this.colors[7],
        textHighlightColor: this.colors[8],
        textColor: "#FFFFFF",
      },
      [ColorShade.LIGHT]: {
        backgroundColor: this.colors[9],
        textHighlightColor: this.colors[10],
        textColor: "#000000",
      },
      [ColorShade.LIGHTEST]: {
        backgroundColor: "#FFFFFF",
        textHighlightColor: this.colors[11],
        textColor: "#000000",
      },
    };
  }
}

export default ColorTheme;
