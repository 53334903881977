import * as React from "react";
import styled from "@emotion/styled";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { AbsoluteLinks } from "../../constants/links";
import GatsbyLink from "../GatsbyLink";
import {
  DesktopAndBigger,
  SmallerThanDesktop,
} from "../../layouts/MediaQueries";
import DesktopBar from "./DesktopBar";
import BurgerNavigationBar from "./BurgerNavigationBar";
import { navigationBar } from "../../constants/z-indices";
import Breadcrumbs from "./Breadcrumbs";
import { contentMaxWidth } from "../../constants/sizes";

const NavigationContainer = styled.nav`
  max-width: ${contentMaxWidth}px;
  width: 100%;
  z-index: ${navigationBar};
`;

export interface NavigationBarLinkItemProps
  extends React.ComponentProps<typeof GatsbyLink> {
  isActive: boolean;
  icon: IGatsbyImageData;
  activeIcon: IGatsbyImageData;
  text: string;
}

export const isLinkItemHighlighted = (
  currentAbsolutePath: string,
  linkItemAbsolutePath: string
) => {
  if (linkItemAbsolutePath === AbsoluteLinks.Home) {
    return currentAbsolutePath === AbsoluteLinks.Home;
  }
  return currentAbsolutePath.startsWith(linkItemAbsolutePath);
};

interface NavigationBarProps {
  headerBarTitleText: string;
  headerBarTitleLinkUrl?: string;
}

const NavigationBar: React.FunctionComponent<NavigationBarProps> = (props) => {
  return (
    <>
      <NavigationContainer>
        <DesktopAndBigger>
          <DesktopBar />
        </DesktopAndBigger>
        <SmallerThanDesktop>
          <BurgerNavigationBar
            headerBarTitleText={props.headerBarTitleText}
            headerBarTitleLinkUrl={props.headerBarTitleLinkUrl}
          />
        </SmallerThanDesktop>
      </NavigationContainer>
      <Breadcrumbs />
    </>
  );
};

export default NavigationBar;
