import styled from "@emotion/styled";
import { css } from "@emotion/react";
import GatsbyLink from "./GatsbyLink";

const clickableIcon = css`
  width: 24px;
  height: 24px;
  padding: 10px;
  box-sizing: content-box;
`;

export const IconLink = styled(GatsbyLink)`
  ${clickableIcon}
`;

export const IconButton = styled.button`
  ${clickableIcon}
`;

export const textButtonStyles = css`
  padding: 10px;

  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;

  color: #000000;
  background-color: #ffffff;
  :hover {
    background-color: #f6f6f6;
  }
`;

export const TextButton = styled.button`
  ${textButtonStyles}
`;
