import React from "react";
import { useLocation } from "@reach/router";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { defaultColorTheme } from "../../../constants/color-themes";
import ContentBlock from "../../../components/ContentBlock";
import ContentElement from "../../../components/ContentElement";
import { ColorShade } from "../../../types/color-theme";
import { AbsoluteLinks } from "../../../constants/links";

const pathToHeaderBarTitleTextMap = new Map();
pathToHeaderBarTitleTextMap.set(AbsoluteLinks.Impressum, "Impressum");
pathToHeaderBarTitleTextMap.set(AbsoluteLinks.Datenschutz, "Datenschutz");

const pathToContentBlockIdMap = new Map();
pathToContentBlockIdMap.set(AbsoluteLinks.Impressum, "impressum");
pathToContentBlockIdMap.set(AbsoluteLinks.Datenschutz, "datenschutz");

const Layout = ({ children }) => {
  const { pathname: currentPath } = useLocation();

  const headerBarTitleText = pathToHeaderBarTitleTextMap.get(currentPath);
  const contentBlockId = pathToContentBlockIdMap.get(currentPath);

  return (
    <DefaultLayout
      headerBarTitleText={headerBarTitleText}
      colorTheme={defaultColorTheme}
    >
      <ContentBlock
        id={contentBlockId}
        colorShade={ColorShade.LIGHTEST}
        hideSpeechOutputPlayButton={true}
      >
        <ContentElement>{children}</ContentElement>
      </ContentBlock>
    </DefaultLayout>
  );
};

export default Layout;
