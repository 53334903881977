import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import GatsbyLink from "./GatsbyLink";
import filterMobileOnlyMdxNodes, {
  filterNotInNavbarMdxNodes,
} from "../utils/filterMobileOnlyMdxNodes";
import { tabletAndBigger } from "../layouts/MediaQueries";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  padding-top: 8px;
  padding-bottom: 8px;

  ${tabletAndBigger} {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-grow: 1;
`;

const LinkItem = styled(GatsbyLink)`
  flex-basis: 24%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 2px;
  padding-bottom: 10px;

  ${tabletAndBigger} {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const LinkIcon = styled(GatsbyImage)`
  max-width: 140px;
`;

const LinkText = styled.span`
  text-align: center;
  line-height: 1.25rem;

  margin-top: 2px;

  ${tabletAndBigger} {
    margin-top: 4px;
  }
`;

interface InlineMenuProps {
  currentPath: string;
}

const InlineMenu: React.FunctionComponent<InlineMenuProps> = (props) => {
  const data = useStaticQuery(graphql`
    query InlineMenuQuery {
      allMdx(sort: { fields: { sortKey: ASC } }) {
        edges {
          node {
            frontmatter {
              key
              title
              icon {
                childImageSharp {
                  gatsbyImageData(width: 140, layout: FIXED, quality: 100)
                }
              }
              isNotInNavbar
              isInNavbarOnMobileOnly
            }
            fields {
              path
              parentPath
            }
          }
        }
      }
    }
  `);

  const directChildNodes = filterNotInNavbarMdxNodes(
    filterMobileOnlyMdxNodes(data.allMdx.edges)
  ).filter(
    ({
      node: {
        fields: { parentPath },
      },
    }) => parentPath === props.currentPath
  );

  const links = directChildNodes.map(({ node: { frontmatter, fields } }) => (
    <LinkItem key={frontmatter.key} to={fields.path}>
      <LinkIcon
        image={frontmatter.icon.childImageSharp.gatsbyImageData}
        alt={frontmatter.title}
      />
      <LinkText>{frontmatter.title}</LinkText>
    </LinkItem>
  ));

  return (
    <Container>
      <Grid>{links}</Grid>
    </Container>
  );
};

export default InlineMenu;
