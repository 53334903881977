import { action, makeObservable, observable } from "mobx";
import ContentBlockModel from "./models/ContentBlockModel";

class ContentBlockStore {
  public contentBlocks: Map<string, ContentBlockModel>;

  constructor() {
    this.contentBlocks = new Map();

    makeObservable(this, {
      contentBlocks: observable,
      registerContentBlock: action,
      unregisterContentBlock: action,
    });
  }

  public registerContentBlock(contentBlockId: string) {
    this.contentBlocks.set(contentBlockId, new ContentBlockModel());
  }

  public unregisterContentBlock(contentBlockId: string) {
    this.contentBlocks.get(contentBlockId).dispose();
    this.contentBlocks.delete(contentBlockId);
  }
}

export default ContentBlockStore;
