import * as React from "react";
import { navigate } from "gatsby";
import { observer } from "mobx-react-lite";
import { Dialog } from "@capacitor/dialog";
import { useStore } from "./StoreProvider";
import { AbsoluteLinks } from "../constants/links";
import isMobile from "../utils/isMobile";

interface AppInitializerProps {
  children?: React.ReactNode;
}

const AppInitializer: React.FunctionComponent<AppInitializerProps> = (
  props
) => {
  const capacitorStore = useStore().capacitorStore;
  const soundStore = useStore().soundStore;

  React.useEffect(() => {
    const initialize = async () => {
      await capacitorStore.synchronizePlatformInformation();

      if (isMobile) {
        capacitorStore.registerAppStateChangeListener((appState) => {
          if (!appState.isActive) {
            soundStore.stopAllSounds();
          }
        });
        await capacitorStore.addPushNotificationRegisteredListener(async () => {
          await capacitorStore.createOrUpdateUser();
          await capacitorStore.addPushNotificationReceivedListener(
            async (notification) => {
              if (!notification.title || !notification.body) {
                // TODO: what do here?
                return;
              }
              if (notification.data.type === "lovestory_update") {
                const result = await Dialog.confirm({
                  title: notification.title,
                  message: notification.body,
                  cancelButtonTitle: "Abbrechen",
                  okButtonTitle: "Zur Liebesgeschichte",
                });
                if (result.value) {
                  await navigate(AbsoluteLinks.Liebesgeschichte);
                }
              }
            }
          );
          await capacitorStore.addPushNotificationActionPerformedListener(
            async (notification) => {
              if (notification.notification.data.type === "lovestory_update") {
                await navigate(AbsoluteLinks.Liebesgeschichte);
              }
            }
          );
        });
        await capacitorStore.registerForPushNotifications();
        if (capacitorStore.platform === "ios") {
          await capacitorStore.hideStatusBar();
        }
        await capacitorStore.hideSplashScreen();
      }
    };
    initialize();

    return () => {
      if (isMobile) {
        capacitorStore.removeAllAppListeners();
        capacitorStore.removeAllPushNotificationListeners();
      }
    };
  }, [capacitorStore]);

  return <>{props.children}</>;
};

export default observer(AppInitializer);
