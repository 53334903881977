import styled from "@emotion/styled";
import { useCombobox } from "downshift";
import Fuse from "fuse.js";
import { navigate } from "gatsby";
import React from "react";
import ColorThemes from "../constants/color-themes";
import useContentNavigationTree from "../hooks/useContentNavigationTree";
import GatsbyLink from "./GatsbyLink";

const Container = styled.div`
  position: relative;
`;

const SearchInput = styled.input`
  min-width: 100px;
  max-width: 300px;
  width: 100%;
  padding: 5px;
  color: #000000;
  background-color: ${ColorThemes.BLACK.colors[11]};

  :focus {
    background-color: #ffffff;
  }

  ::placeholder {
    color: ${ColorThemes.BLACK.colors[6]};
  }
`;

const SuggestionsContainer = styled.div`
  position: absolute;
  bottom: 40px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 1px 6px 0px rgba(128, 128, 128, 0.5);
`;

const SuggestionsList = styled.ul`
  list-style-type: none;
  margin: 0px;
  padding: 0px;
`;

const SuggestionsListEntry = styled.li<{ isHighlighted: boolean }>`
  cursor: pointer;
  padding: 10px;
  background-color: ${(props) =>
    props.isHighlighted ? ColorThemes.BLACK.colors[11] : "transparent"};
`;

const SuggestionTitle = styled.span`
  color: #000000;
  font-weight: 600;
  display: block;
`;

const SuggestionDescription = styled.span`
  color: ${ColorThemes.BLACK.colors[8]};
`;

interface SearchablePageEntity {
  resultTitle: string;
  resultDescription: string;
  keywords: string[];
  path: string;
}

const fuseOptions: Fuse.IFuseOptions<SearchablePageEntity> = {
  shouldSort: true,
  includeScore: false,
  threshold: 0.3,
  location: 0,
  distance: 100,
  minMatchCharLength: 1,
  keys: [
    {
      name: "resultTitle",
      weight: 0.6,
    },
    {
      name: "keywords",
      weight: 0.4,
    },
    {
      name: "resultDescription",
      weight: 0.2,
    },
  ],
};

interface SearchFieldProps {
  className?: string;
}

const SearchField: React.FunctionComponent<SearchFieldProps> = (props) => {
  const contentNavigationTree = useContentNavigationTree();
  const allNavigationNodes = contentNavigationTree.getAllNavigationNodes();
  const searchablePageEntities: SearchablePageEntity[] = allNavigationNodes.map(
    (node) => ({
      resultTitle: node.search?.resultTitle || node.seo?.title || node.title,
      resultDescription:
        node.search?.resultDescription || node.seo?.description,
      keywords: node.search?.keywords || [],
      path: node.path,
    })
  );
  const fuse = new Fuse(searchablePageEntities, fuseOptions);

  const [inputItems, setInputItems] = React.useState<SearchablePageEntity[]>(
    []
  );
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: inputItems,
    itemToString: () => "",
    onInputValueChange: ({ inputValue }) => {
      const results = fuse.search(inputValue || "");
      const searchResults: SearchablePageEntity[] = results
        .map((result) => result.item)
        .slice(0, 3);
      setInputItems(searchResults);
    },
    onSelectedItemChange: ({ selectedItem }) =>
      selectedItem && navigate(selectedItem.path),
  });

  const suggestionListEntries = inputItems.map((item, index) => (
    <SuggestionsListEntry
      key={`search-suggestion-${index}`}
      isHighlighted={highlightedIndex === index}
      {...getItemProps({ item, index })}
    >
      <GatsbyLink to={item.path}>
        <SuggestionTitle>{item.resultTitle}</SuggestionTitle>
        <SuggestionDescription>{item.resultDescription}</SuggestionDescription>
      </GatsbyLink>
    </SuggestionsListEntry>
  ));

  return (
    <Container className={props.className}>
      <SearchInput placeholder="Suchen..." {...getInputProps()} />
      <SuggestionsContainer>
        <SuggestionsList {...getMenuProps()}>
          {isOpen && suggestionListEntries}
        </SuggestionsList>
      </SuggestionsContainer>
    </Container>
  );
};

export default SearchField;
