import { action, computed, makeObservable, observable } from "mobx";
import TriggerWordModel from "./TriggerWordModel";
import { TriggerWord } from "../../constants/trigger-words";

interface CurrentlyTriggeredWord {
  triggerWord: TriggerWordModel;
  timeoutHandle: NodeJS.Timeout;
}

class ContentBlockModel {
  public contentBlockId: string;
  public registeredTriggerWords: TriggerWordModel[];
  public currentlyTriggeredWords: CurrentlyTriggeredWord[];

  constructor() {
    this.contentBlockId = "";
    this.registeredTriggerWords = [];
    this.currentlyTriggeredWords = [];

    makeObservable(this, {
      contentBlockId: observable,
      registeredTriggerWords: observable,
      currentlyTriggeredWords: observable,
      allCurrentlyTriggeredWordsLowerCase: computed,
      registerTriggerWord: action,
      findMatchingRegisteredTriggerWord: action,
      findMatchingCurrentlyTriggerWord: action,
      removeCurrentlyTriggeredWord: action,
      addCurrentlyTriggeredWord: action,
      dispose: action,
    });
  }

  public get allCurrentlyTriggeredWordsLowerCase() {
    const allCurrentlyTriggeredWordsLowerCase = [];
    this.currentlyTriggeredWords.forEach((currentlyTriggeredWord) =>
      allCurrentlyTriggeredWordsLowerCase.push(
        ...currentlyTriggeredWord.triggerWord.allWordsLowerCase
      )
    );
    return allCurrentlyTriggeredWordsLowerCase;
  }

  public registerTriggerWord(triggerWord: TriggerWord) {
    this.registeredTriggerWords.push(
      new TriggerWordModel(
        triggerWord.word,
        triggerWord.similarWords,
        triggerWord.ssmlFileRelativePath
      )
    );
  }

  public findMatchingRegisteredTriggerWord(word: string) {
    return this.registeredTriggerWords.find(
      (triggerWord) =>
        triggerWord.allWordsLowerCase.indexOf(word.toLowerCase()) !== -1
    );
  }

  public findMatchingCurrentlyTriggerWord(word: string) {
    return this.currentlyTriggeredWords.find(
      (currentlyTriggeredWord) =>
        currentlyTriggeredWord.triggerWord.allWordsLowerCase.indexOf(
          word.toLowerCase()
        ) !== -1
    );
  }

  public removeCurrentlyTriggeredWord(word: string) {
    const matchingCurrentlyTriggeredWord =
      this.findMatchingCurrentlyTriggerWord(word);
    if (!matchingCurrentlyTriggeredWord) {
      return;
    }
    clearTimeout(matchingCurrentlyTriggeredWord.timeoutHandle);
    this.currentlyTriggeredWords.splice(
      this.currentlyTriggeredWords.indexOf(matchingCurrentlyTriggeredWord),
      1
    );
  }

  public addCurrentlyTriggeredWord(word: string) {
    const matchingRegisteredTriggerWord =
      this.findMatchingRegisteredTriggerWord(word);
    if (!matchingRegisteredTriggerWord) {
      return;
    }

    this.removeCurrentlyTriggeredWord(word);

    const timeoutHandle = setTimeout(
      () => this.removeCurrentlyTriggeredWord(word),
      2000
    );
    const currentlyTriggeredWord = {
      triggerWord: matchingRegisteredTriggerWord,
      timeoutHandle,
    };
    this.currentlyTriggeredWords.push(currentlyTriggeredWord);
  }

  public dispose() {
    this.currentlyTriggeredWords
      .map((word) => word.timeoutHandle)
      .forEach(clearTimeout);
  }
}

export default ContentBlockModel;
