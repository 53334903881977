import * as React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import { NavigationBarLinkItemProps } from "./index";
import { ColorThemeContext } from "../../layouts/DefaultLayout";
import GatsbyLink from "../GatsbyLink";

const LinkElement = styled(GatsbyLink)`
  padding: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #000000;
`;

const LinkIcon = styled(GatsbyImage)`
  max-width: 50px;
  max-height: 50px;
  margin-right: 10px;
`;

const BurgerNavigationBarLinkItem: React.FunctionComponent<
  NavigationBarLinkItemProps
> = ({ isActive, icon, activeIcon, text, children, ...gatsbyLinkProps }) => {
  const colorTheme = React.useContext(ColorThemeContext);
  return (
    <LinkElement
      css={css`
        background-color: ${isActive && !activeIcon
          ? colorTheme.colors[2]
          : "transparent"};
      `}
      {...gatsbyLinkProps}
    >
      <LinkIcon image={isActive && activeIcon ? activeIcon : icon} alt={text} />
      {text}
      {children}
    </LinkElement>
  );
};

export default BurgerNavigationBarLinkItem;
