import styled from "@emotion/styled";
import React from "react";
import ColorThemes from "../constants/color-themes";
import GatsbyLink from "./GatsbyLink";
import SearchField from "./SearchField";
import { AbsoluteLinks } from "../constants/links";

const Container = styled.div`
  color: #ffffff;
  background-color: ${ColorThemes.BLACK.colors[1]};
  min-height: 160px;

  align-self: stretch;
  margin-top: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  align-content: flex-start;

  margin-left: -20px;
  margin-right: -20px;

  max-width: 1000px;
  width: 100%;
`;

const StyledSearchField = styled(SearchField)`
  flex-grow: 1;
  margin: 20px;
`;

const FooterLink = styled(GatsbyLink)`
  word-wrap: break-word;
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
`;

interface FooterProps {}

const Footer: React.FunctionComponent<FooterProps> = (props) => {
  return (
    <Container>
      <ContentBox>
        <StyledSearchField />
        <FooterLink to={AbsoluteLinks.Datenschutz}>
          Datenschutzerklärung
        </FooterLink>
        <FooterLink to={AbsoluteLinks.Impressum}>Impressum</FooterLink>
      </ContentBox>
    </Container>
  );
};

export default Footer;
