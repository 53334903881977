import { css } from "@emotion/react";
import { indentationStep } from "../../constants/sizes";

export default css`
  h1 {
    font-size: 1.6rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;

    margin-top: 35px;
    margin-bottom: 10px;

    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;

    :first-child {
      margin-top: 10px;
    }
  }

  p,
  li,
  ul,
  ol {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ul {
    padding-inline-start: ${indentationStep}px;
    list-style: disc;
  }

  ol {
    padding-inline-start: ${indentationStep}px;
    list-style-type: decimal;
  }
`;
