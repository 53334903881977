import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { navigate, useLocation } from "@reach/router";
import { ColorThemeContext } from "../../layouts/DefaultLayout";
import GatsbyLink from "../GatsbyLink";
import { IconButton, IconLink } from "../Clickables";
import { AbsoluteLinks } from "../../constants/links";
import {
  burgerMenuBlurredBackground,
  burgerMenuDrawer,
} from "../../constants/z-indices";
import { graphql, useStaticQuery } from "gatsby";
import filterMobileOnlyMdxNodes, {
  filterNotInNavbarMdxNodes,
} from "../../utils/filterMobileOnlyMdxNodes";
import BurgerNavigationBarLinkItem from "./BurgerNavigationBarLinkItem";
import { isLinkItemHighlighted } from "./index";
import { Back, BurgerMenu, Home } from "../Icons";

const Container = styled.div`
  position: relative;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderBarTitleContainer = styled.div`
  flex-grow: 1;
  text-align: center;
`;

const BlurredBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: ${burgerMenuBlurredBackground};
`;

const Drawer = styled.ul`
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  z-index: ${burgerMenuDrawer};
  display: flex;
  flex-direction: column;
`;

interface BurgerNavigationBarProps {
  headerBarTitleText: string;
  headerBarTitleLinkUrl?: string;
}

const BurgerNavigationBar: React.FunctionComponent<BurgerNavigationBarProps> = (
  props
) => {
  const [isDrawerVisible, setDrawerVisible] = React.useState<boolean>(false);

  const showDrawer = () => setDrawerVisible(true);

  const hideDrawer = () => setDrawerVisible(false);

  const toggleDrawer = () => (isDrawerVisible ? hideDrawer() : showDrawer());

  const location = useLocation();

  const data = useStaticQuery(graphql`
    {
      allMdx(
        filter: { fields: { hierarchyLevel: { lte: 1 } } }
        sort: [
          { fields: { hierarchyLevel: ASC } }
          { fields: { sortKey: ASC } }
        ]
      ) {
        edges {
          node {
            frontmatter {
              key
              title
              isNotInNavbar
              isInNavbarOnMobileOnly
              icon {
                childImageSharp {
                  gatsbyImageData(width: 100, layout: FIXED, quality: 100)
                }
              }
              iconActive {
                childImageSharp {
                  gatsbyImageData(width: 100, layout: FIXED, quality: 100)
                }
              }
            }
            fields {
              path
            }
          }
        }
      }
    }
  `);
  const filteredNodes = filterNotInNavbarMdxNodes(
    filterMobileOnlyMdxNodes(data.allMdx.edges)
  );
  const burgerNavigationBarLinkItems = filteredNodes.map(({ node }: any) => (
    <li key={node.frontmatter.key}>
      <BurgerNavigationBarLinkItem
        to={node.fields.path}
        isActive={isLinkItemHighlighted(location.pathname, node.fields.path)}
        icon={node.frontmatter.icon.childImageSharp.gatsbyImageData}
        activeIcon={
          node.frontmatter.iconActive?.childImageSharp?.gatsbyImageData
        }
        text={node.frontmatter.title}
        onClick={hideDrawer}
      />
    </li>
  ));

  const onBackButtonClicked = async () => {
    hideDrawer();
    await navigate(-1);
  };

  const colorTheme = React.useContext(ColorThemeContext);

  const headerBarTitleElement = props.headerBarTitleLinkUrl ? (
    <GatsbyLink to={props.headerBarTitleLinkUrl}>
      {props.headerBarTitleText}
    </GatsbyLink>
  ) : (
    props.headerBarTitleText
  );

  return (
    <>
      <Container
        css={css`
          background-color: ${colorTheme.colors[1]};
        `}
      >
        <IconButton onClick={onBackButtonClicked}>
          <Back />
        </IconButton>
        <HeaderBarTitleContainer>
          {headerBarTitleElement}
        </HeaderBarTitleContainer>
        <IconLink to={AbsoluteLinks.Home} onClick={hideDrawer}>
          <Home />
        </IconLink>
        <IconButton onClick={toggleDrawer}>
          <BurgerMenu />
        </IconButton>
      </Container>
      {isDrawerVisible && (
        <>
          <BlurredBackground onClick={hideDrawer} />
          <Drawer>{burgerNavigationBarLinkItems}</Drawer>
        </>
      )}
    </>
  );
};

export default BurgerNavigationBar;
