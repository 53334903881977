import * as React from "react";
import { PositionProperty } from "csstype";
import { throttle, debounce } from "lodash";
import { contentMaxWidth, safeAreaInsetTop } from "../constants/sizes";
import { css } from "@emotion/react";

const isBrowser = typeof window !== "undefined";
const isInternetExplorer: boolean =
  // @ts-ignore
  isBrowser && !!window.document.documentMode;

const getCurrentScrollTop = () =>
  window.pageYOffset || document.documentElement.scrollTop;

const Sticky: React.FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = (
  props
) => {
  const placeholderDiv = React.useRef();
  const [placeholderHeight, setPlaceholderHeight] = React.useState<number>(0);
  const stickyDiv = React.useRef();
  const [isLockedToTop, setLockedToTop] = React.useState<boolean>(false);
  const [stickyContainerPosition, setStickyContainerPosition] =
    React.useState<PositionProperty>("sticky");
  const placeholderDivTop = React.useRef<number>(0);
  const placeholderDivLeft = React.useRef<number>(0);

  const makeDirty = () => {
    setLockedToTop(getCurrentScrollTop() > placeholderDivTop.current);
  };

  const throttledMakeDirty = React.useCallback(throttle(makeDirty, 25), []);

  const recalculatePlaceholderDivTop = React.useCallback(() => {
    if (!placeholderDiv.current) {
      return;
    }
    const relativePlaceholderDivTop =
      placeholderDiv.current.getBoundingClientRect().top;
    const absolutePlaceholderDivTop = Math.round(
      relativePlaceholderDivTop + getCurrentScrollTop()
    );
    placeholderDivTop.current = absolutePlaceholderDivTop;

    const absolutePlaceholderDivLeft =
      placeholderDiv.current.getBoundingClientRect().left;
    placeholderDivLeft.current = absolutePlaceholderDivLeft;

    throttledMakeDirty();
  }, [throttledMakeDirty]);

  const debouncedRecalculatePlaceholderDivTop = React.useCallback(
    debounce(recalculatePlaceholderDivTop, 100),
    []
  );

  React.useEffect(() => {
    if (!isInternetExplorer) {
      return;
    }
    window.addEventListener("scroll", () => {
      debouncedRecalculatePlaceholderDivTop();
      throttledMakeDirty();
    });
    window.addEventListener("resize", () => {
      debouncedRecalculatePlaceholderDivTop();
      throttledMakeDirty();
    });
  }, [debouncedRecalculatePlaceholderDivTop, throttledMakeDirty]);

  React.useLayoutEffect(() => {
    if (!isInternetExplorer) {
      return;
    }
    recalculatePlaceholderDivTop();
  }, [recalculatePlaceholderDivTop]);

  React.useEffect(() => {
    if (!isInternetExplorer) {
      return;
    }
    setStickyContainerPosition(
      isInternetExplorer ? (isLockedToTop ? "fixed" : "static") : "sticky"
    );
  }, [isLockedToTop]);

  React.useLayoutEffect(() => {
    if (!isInternetExplorer) {
      return;
    }
    stickyDiv.current &&
      setPlaceholderHeight(
        isLockedToTop ? stickyDiv.current.getBoundingClientRect().height : 0
      );
  }, [isLockedToTop]);

  return (
    <>
      <div
        ref={placeholderDiv}
        style={{
          visibility: "hidden",
          maxWidth: contentMaxWidth,
          width: "100%",
          height: placeholderHeight,
        }}
      />
      <div
        ref={stickyDiv}
        {...props}
        css={css`
          position: -webkit-sticky;
          position: ${stickyContainerPosition};
          top: ${safeAreaInsetTop};
          left: ${placeholderDivLeft.current};
        `}
      >
        {props.children}
      </div>
    </>
  );
};

export default Sticky;
