import * as React from "react";
import { Global, css } from "@emotion/react";
import emotionTailwindPreflight from "emotion-tailwind-preflight";
import { ColorThemeContext } from "../../layouts/DefaultLayout";
import {
  safeAreaInsetLeft,
  safeAreaInsetRight,
  safeAreaInsetTop,
  safeAreaInsetBottom,
} from "../../constants/sizes";

const GlobalStyler: React.FunctionComponent<{}> = (props) => {
  return (
    <>
      <Global
        styles={css`
          ${emotionTailwindPreflight}

          html {
            font-family: ABeeZee, sans-serif;
            font-size: 20px;

            overflow-y: scroll;
            background-color: #ffffff;

            margin-left: ${safeAreaInsetLeft};
            margin-right: ${safeAreaInsetRight};
            margin-top: ${safeAreaInsetTop};
            margin-bottom: ${safeAreaInsetBottom};
          }

          .CookieConsent {
            padding-left: ${safeAreaInsetLeft};
            padding-right: ${safeAreaInsetRight};
            padding-bottom: ${safeAreaInsetBottom};

            div:first-child {
              flex: 1 0 !important;
            }
          }
        `}
      />
      {props.children}
    </>
  );
};

export default GlobalStyler;
