import * as React from "react";
import styled from "@emotion/styled";
import useContentNavigationTree from "../hooks/useContentNavigationTree";
import GatsbyLink from "./GatsbyLink";
import { ColorThemeContext } from "../layouts/DefaultLayout";
import ColorTheme from "../types/color-theme";
import ContentElement from "./ContentElement";

const Link = styled(GatsbyLink)`
  text-decoration: none;
`;

const LinkContentElement = styled(ContentElement)<{
  currentColorTheme: ColorTheme;
}>`
  padding-top: 10px;
  padding-bottom: 10px;

  background-color: ${(props) => props.currentColorTheme.colors[2]};
  :hover {
    background-color: ${(props) => props.currentColorTheme.colors[3]};
  }
`;

interface LinkBoxProps {
  absolutePath: string;
  customTitle?: string;
  customPictogramRelativePath?: string;
}

const LinkBox: React.FunctionComponent<LinkBoxProps> = (props) => {
  const currentColorTheme = React.useContext(ColorThemeContext);

  const navigationNode = useContentNavigationTree().getNodeByPath(
    props.absolutePath
  );

  return (
    <Link to={props.absolutePath}>
      <LinkContentElement
        currentColorTheme={currentColorTheme}
        isLink={true}
        pictogram={
          props.customPictogramRelativePath || navigationNode.iconRelativePath
        }
        pictogramAltText={props.customTitle || navigationNode.title}
      >
        <p>{props.customTitle || navigationNode.title}</p>
      </LinkContentElement>
    </Link>
  );
};

export default LinkBox;
