import * as React from "react";
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import DefaultLayout from "../layouts/DefaultLayout";
import InlineMenu from "../components/InlineMenu";
import ColorThemes from "../constants/color-themes";
import useContentNavigationTree from "../hooks/useContentNavigationTree";
import ContentElement from "../components/ContentElement";
import ContentBlock, {
  KlarUndEinfachContentBlock,
} from "../components/ContentBlock";
import Illustration from "../components/Illustration";
import ImageFloor, { KarlUndKlaraImageFloor } from "../components/ImageFloor";
import { IndentOnce } from "../components/Indentations";
import { Spacer } from "../components/Spacers";
import LinkBox from "../components/LinkBox";
import { MobileOnly, DesktopOnly } from "../layouts/MediaQueries";
import GatsbyLink from "../components/GatsbyLink";
import Seo from "../components/Seo";

export const Link: React.FunctionComponent<React.ComponentProps<"a">> = (
  props
) => {
  if (!props.href) {
    throw new Error("Link is missing href prop.");
  }
  const isInternalLink = props.href.startsWith("/");
  if (isInternalLink) {
    const isTargetBlank = props.target && props.target === "_blank";
    if (isTargetBlank) {
      return <a {...props} />;
    }
    const { href, ...otherProps } = props;
    return <GatsbyLink to={href} {...otherProps} />;
  }
  return <a target="_blank" rel="nofollow noopener noreferrer" {...props} />;
};

const components = {
  InlineMenu,
  ContentElement,
  ContentBlock,
  KlarUndEinfachContentBlock,
  Illustration,
  ImageFloor,
  KarlUndKlaraImageFloor,
  IndentOnce,
  Spacer,
  LinkBox,
  DesktopOnly,
  MobileOnly,
  a: Link,
};

const evaluateHeaderBarTitleContent = (
  navigationTree,
  currentNavigationNodeKey
) => {
  const currentNavigationNode = navigationTree.getNodeByKey(
    currentNavigationNodeKey
  );
  const mainTopicNavigationNodeHierarchyLevel = 1;
  if (
    currentNavigationNode.hierarchyLevel <=
    mainTopicNavigationNodeHierarchyLevel
  ) {
    return {
      titleText: currentNavigationNode.title,
    };
  }
  const mainTopicNavigationNode =
    navigationTree.getRelatedParentNodeOfHierarchyLevel(
      currentNavigationNode,
      mainTopicNavigationNodeHierarchyLevel
    );
  return {
    titleText: mainTopicNavigationNode.title,
    titleLinkUrl: mainTopicNavigationNode.path,
  };
};

const ContentPageTemplate = ({ pageContext: { frontmatter }, children }) => {
  const navigationTree = useContentNavigationTree();
  const headerBarTitleContent = evaluateHeaderBarTitleContent(
    navigationTree,
    frontmatter.key
  );
  return (
    <DefaultLayout
      headerBarTitleText={headerBarTitleContent.titleText}
      headerBarTitleLinkUrl={headerBarTitleContent.titleLinkUrl}
      colorTheme={ColorThemes[frontmatter.colorTheme]}
      isFullscreenContent={frontmatter.isFullscreenContent}
    >
      <MDXProvider components={components}>{children}</MDXProvider>
    </DefaultLayout>
  );
};

export const pageQuery = graphql`
  query ContentPageByPath($path: String!) {
    mdx(fields: { path: { eq: $path } }) {
      frontmatter {
        key
        title
        seo {
          title
          description
        }
        colorTheme
        search {
          keywords
          resultTitle
          resultDescription
        }
        isFullscreenContent
      }
      fields {
        path
      }
    }
  }
`;

export const Head = ({ pageContext: { frontmatter }, location }) => (
  <Seo
    relativePageUrl={location.pathname}
    pageTitle={frontmatter.seo?.title}
    description={frontmatter.seo?.description}
  />
);

export default ContentPageTemplate;
