const RelativeLinks = {
  Home: "/",
  Frau: "/frau",
  Koerper: "/koerper",
  Brueste: "/brueste",
  Scheide: "/scheide",
  Gebaermutter: "/gebaermutter",
  Koerperbehaarung: "/koerperbehaarung",
  Periode: "/periode",
  SexuelleErregung: "/sexuelle-erregung",
  Pubertaet: "/pubertaet",
  Hygiene: "/hygiene",
  Mann: "/mann",
  PenisUndHoden: "/penis-und-hoden",
  Gefuehle: "/gefuehle",
  DuUndIch: "/du-und-ich",
  Freundschaft: "/freundschaft",
  Verliebt: "/verliebt",
  Partnerschaft: "/partnerschaft",
  Gut: "/gut",
  Geheimnisse: "/geheimnisse",
  MeinKoerperGehoertMir: "/mein-koerper-gehoert-mir",
  Schlecht: "/schlecht",
  Sex: "/sex",
  WasIstSex: "/was-ist-sex",
  SexIst: "/sex-ist",
  LustUndOrgasmus: "/lust-und-orgasmus",
  Sexpraktiken: "/sexpraktiken",
  Selbstbefriedigung: "/selbstbefriedigung",
  SexuelleOrientierung: "/sexuelle-orientierung",
  ErstesMal: "/erstes-mal",
  Verhuetung: "/verhuetung",
  Schwangerschaft: "/schwangerschaft",
  Pornografie: "/pornografie",
  SexuelleRechte: "/sexuelle-rechte",
  SexuelleGewalt: "/sexuelle-gewalt",
  GesetzeUndStrafen: "/gesetze-und-strafen",
  Information: "/information",
  Arzt: "/arzt",
  Krankheiten: "/krankheiten",
  Beschneidung: "/beschneidung",
  Beratung: "/beratung",
  FragenUndAntworten: "/fragen-und-antworten",
  Links: "/links",
  UeberUns: "/ueber-uns",
  Kontakt: "/kontakt",
  Quiz: "/quiz",
  Liebesgeschichte: "/liebesgeschichte",
  AndroidApp: "/android-app",
  Impressum: "/impressum",
  Datenschutz: "/datenschutz",
};

export const AbsoluteLinks = {
  Home: RelativeLinks.Home,

  Frau: RelativeLinks.Frau + "/",
  FrauKoerper: RelativeLinks.Frau + RelativeLinks.Koerper + "/",
  FrauKoerperBrueste:
    RelativeLinks.Frau + RelativeLinks.Koerper + RelativeLinks.Brueste + "/",
  FrauKoerperScheide:
    RelativeLinks.Frau + RelativeLinks.Koerper + RelativeLinks.Scheide + "/",
  FrauKoerperGebaermutter:
    RelativeLinks.Frau +
    RelativeLinks.Koerper +
    RelativeLinks.Gebaermutter +
    "/",
  FrauKoerperKoerperbehaarung:
    RelativeLinks.Frau +
    RelativeLinks.Koerper +
    RelativeLinks.Koerperbehaarung +
    "/",
  FrauPeriode: RelativeLinks.Frau + RelativeLinks.Periode + "/",
  FrauSexuelleErregung:
    RelativeLinks.Frau + RelativeLinks.SexuelleErregung + "/",
  FrauPubertaet: RelativeLinks.Frau + RelativeLinks.Pubertaet + "/",
  FrauHygiene: RelativeLinks.Frau + RelativeLinks.Hygiene + "/",

  Mann: RelativeLinks.Mann + "/",
  MannKoerper: RelativeLinks.Mann + RelativeLinks.Koerper + "/",
  MannKoerperPenisUndHoden:
    RelativeLinks.Mann +
    RelativeLinks.Koerper +
    RelativeLinks.PenisUndHoden +
    "/",
  MannKoerperKoerperbehaarung:
    RelativeLinks.Mann +
    RelativeLinks.Koerper +
    RelativeLinks.Koerperbehaarung +
    "/",
  MannSexuelleErregung:
    RelativeLinks.Mann + RelativeLinks.SexuelleErregung + "/",
  MannPubertaet: RelativeLinks.Mann + RelativeLinks.Pubertaet + "/",
  MannHygiene: RelativeLinks.Mann + RelativeLinks.Hygiene + "/",

  Gefuehle: RelativeLinks.Gefuehle + "/",
  GefuehleDuUndIch: RelativeLinks.Gefuehle + RelativeLinks.DuUndIch + "/",
  GefuehleDuUndIchFreundschaft:
    RelativeLinks.Gefuehle +
    RelativeLinks.DuUndIch +
    RelativeLinks.Freundschaft +
    "/",
  GefuehleDuUndIchVerliebt:
    RelativeLinks.Gefuehle +
    RelativeLinks.DuUndIch +
    RelativeLinks.Verliebt +
    "/",
  GefuehleDuUndIchPartnerschaft:
    RelativeLinks.Gefuehle +
    RelativeLinks.DuUndIch +
    RelativeLinks.Partnerschaft +
    "/",
  GefuehleGut: RelativeLinks.Gefuehle + RelativeLinks.Gut + "/",
  GefuehleGutGeheimnisse:
    RelativeLinks.Gefuehle +
    RelativeLinks.Gut +
    RelativeLinks.Geheimnisse +
    "/",
  GefuehleGutMeinKoerperGehoertMir:
    RelativeLinks.Gefuehle +
    RelativeLinks.Gut +
    RelativeLinks.MeinKoerperGehoertMir +
    "/",
  GefuehleSchlecht: RelativeLinks.Gefuehle + RelativeLinks.Schlecht + "/",
  GefuehleSchlechtGeheimnisse:
    RelativeLinks.Gefuehle +
    RelativeLinks.Schlecht +
    RelativeLinks.Geheimnisse +
    "/",

  Sex: RelativeLinks.Sex + "/",
  SexWasIstSex: RelativeLinks.Sex + RelativeLinks.WasIstSex + "/",
  SexWasIstSexSexIst:
    RelativeLinks.Sex + RelativeLinks.WasIstSex + RelativeLinks.SexIst + "/",
  SexWasIstSexLustUndOrgasmus:
    RelativeLinks.Sex +
    RelativeLinks.WasIstSex +
    RelativeLinks.LustUndOrgasmus +
    "/",
  SexWasIstSexSexpraktiken:
    RelativeLinks.Sex +
    RelativeLinks.WasIstSex +
    RelativeLinks.Sexpraktiken +
    "/",
  SexWasIstSexSelbstbefriedigung:
    RelativeLinks.Sex +
    RelativeLinks.WasIstSex +
    RelativeLinks.Selbstbefriedigung +
    "/",
  SexWasIstSexErstesMal:
    RelativeLinks.Sex + RelativeLinks.WasIstSex + RelativeLinks.ErstesMal + "/",
  SexSexuelleOrientierung:
    RelativeLinks.Sex + RelativeLinks.SexuelleOrientierung + "/",
  SexVerhuetung: RelativeLinks.Sex + RelativeLinks.Verhuetung + "/",
  SexSchwangerschaft: RelativeLinks.Sex + RelativeLinks.Schwangerschaft + "/",
  SexPornografie: RelativeLinks.Sex + RelativeLinks.Pornografie + "/",
  SexSexuelleRechte: RelativeLinks.Sex + RelativeLinks.SexuelleRechte + "/",
  SexSexuelleGewalt: RelativeLinks.Sex + RelativeLinks.SexuelleGewalt + "/",
  SexGesetzeUndStrafen:
    RelativeLinks.Sex + RelativeLinks.GesetzeUndStrafen + "/",

  Information: RelativeLinks.Information + "/",
  InformationArzt: RelativeLinks.Information + RelativeLinks.Arzt + "/",
  InformationArztKrankheiten:
    RelativeLinks.Information +
    RelativeLinks.Arzt +
    RelativeLinks.Krankheiten +
    "/",
  InformationArztBeschneidung:
    RelativeLinks.Information +
    RelativeLinks.Arzt +
    RelativeLinks.Beschneidung +
    "/",
  InformationBeratung: RelativeLinks.Information + RelativeLinks.Beratung + "/",
  InformationFragenUndAntworten:
    RelativeLinks.Information + RelativeLinks.FragenUndAntworten + "/",
  InformationLinks: RelativeLinks.Information + RelativeLinks.Links + "/",
  InformationUeberUns: RelativeLinks.Information + RelativeLinks.UeberUns + "/",
  InformationUeberUnsKontakt:
    RelativeLinks.Information +
    RelativeLinks.UeberUns +
    RelativeLinks.Kontakt +
    "/",

  Quiz: RelativeLinks.Quiz + "/",

  Liebesgeschichte: RelativeLinks.Liebesgeschichte + "/",

  AndroidApp: RelativeLinks.AndroidApp + "/",

  Impressum: RelativeLinks.Impressum + "/",

  Datenschutz: RelativeLinks.Datenschutz + "/",
};
