module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-178477781-2","G-B60NGWMEN9"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/@flogy/gatsby-theme-fgs-legal/gatsby-browser.js'),
      options: {"plugins":[],"currentWebsiteUrl":"https://klarundeinfach.ch","companyNominative":"Stiftung Schürmatt","companyDative":"der Stiftung Schürmatt","companyAccusative":"die Stiftung Schürmatt","companyLocation":"Zetzwil","impressum":{"path":"/impressum","addresses":[{"title":"dummyContent","company":"dummyContent","companyDescription":"dummyContent","name":"dummyContent","street":"dummyContent","location":"dummyContent","website":"dummyContent","email":"dummyContent"}]},"dataPrivacy":{"path":"/datenschutz","usedFeatures":["welchePersonendaten","zweckeAllgemein","schutz","aufbewahrungsdauer","cookies","googleAnalytics","weitereTools"],"lastChangedDate":"24.04.2023","responsiblePerson":{"nameAccusative":"Frau Liliana Peterhans","email":"kommunikation@schuermatt.ch"}},"cookieBanner":{"enable":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-50},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Klar und Einfach","short_name":"Klar Einfach","start_url":"/","background_color":"#FFFFFF","theme_color":"#000000","display":"standalone","icon":"src/images/favicon-64x64.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8166a419ad5977c80a303342ae391934"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
