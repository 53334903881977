import { graphql, useStaticQuery } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";

const removeAnchor = (path: string) => {
  const index = path.indexOf("#");
  if (index !== -1) {
    return path.substring(0, index);
  }
  return path;
};

class NavigationTree {
  rootNode: NavigationNode;

  constructor(rootNode: NavigationNode) {
    this.rootNode = rootNode;
  }

  getAllNavigationNodes = (): NavigationNode[] => {
    const getChildren = (navigationNode: NavigationNode): NavigationNode[] => {
      const children = [...navigationNode.children];
      navigationNode.children.forEach((child) =>
        children.push(...getChildren(child))
      );
      return children;
    };
    return [this.rootNode, ...getChildren(this.rootNode)];
  };

  getNodeByKey = (desiredKey: string): NavigationNode =>
    this.getAllNavigationNodes().find(
      (navigationNode) => navigationNode.key === desiredKey
    );

  getNodeByPath = (desiredPath: string): NavigationNode => {
    const cleanDesiredPath = removeAnchor(desiredPath);
    return this.getAllNavigationNodes().find(
      (navigationNode) => navigationNode.path === cleanDesiredPath
    );
  };

  getRelatedParentNodeOfHierarchyLevel = (
    navigationNode: NavigationNode,
    desiredHierarchyLevel: number
  ): NavigationNode => {
    const checkParent = (navigationNode: NavigationNode) => {
      if (navigationNode.parent.hierarchyLevel === desiredHierarchyLevel) {
        return navigationNode.parent;
      }
      return checkParent(navigationNode.parent);
    };
    return checkParent(navigationNode);
  };
}

export interface NavigationNode {
  parent: NavigationNode | null;
  children: NavigationNode[];
  key: string;
  title: string;
  seo: {
    title: string;
    description: string;
  };
  colorTheme: string;
  hierarchyLevel: number;
  parentPath: string;
  path: string;
  sortKey: number;
  iconRelativePath: string;
  icon: IGatsbyImageData;
  isInNavbarOnMobileOnly: boolean;
  isNotInNavbar: boolean;
  search: {
    keywords: string[];
    resultTitle?: string;
    resultDescription?: string;
  } | null;
}

const isRootNode = (node) => node.fields.hierarchyLevel === 0;

const buildNavigationNode: (
  node: any,
  parentNavigationNode?: NavigationNode
) => NavigationNode = (node, parentNavigationNode) => ({
  children: [],
  parent: parentNavigationNode || null,
  title: node.frontmatter.title,
  seo: node.frontmatter.seo,
  key: node.frontmatter.key,
  colorTheme: node.frontmatter.colorTheme,
  hierarchyLevel: node.fields.hierarchyLevel,
  parentPath: node.fields.parentPath,
  path: node.fields.path,
  sortKey: node.fields.sortKey,
  iconRelativePath: node.frontmatter.icon.relativePath,
  icon: node.frontmatter.icon.childImageSharp.gatsbyImageData,
  isInNavbarOnMobileOnly: node.frontmatter.isInNavbarOnMobileOnly,
  isNotInNavbar: node.frontmatter.isNotInNavbar,
  search: node.frontmatter.search || null,
});

const useContentNavigationTree = () => {
  const data = useStaticQuery(graphql`
    query NavigationTreeQuery {
      allMdx(
        sort: [
          { fields: { hierarchyLevel: ASC } }
          { fields: { sortKey: ASC } }
        ]
      ) {
        edges {
          node {
            frontmatter {
              key
              colorTheme
              title
              seo {
                title
                description
              }
              isInNavbarOnMobileOnly
              isNotInNavbar
              icon {
                childImageSharp {
                  gatsbyImageData(width: 140, layout: FIXED, quality: 100)
                }
                relativePath
              }
              search {
                keywords
                resultTitle
                resultDescription
              }
            }
            fields {
              path
              hierarchyLevel
              parentPath
              sortKey
            }
          }
        }
      }
    }
  `);
  const { node: rootNode } = data.allMdx.edges.find(({ node }) =>
    isRootNode(node)
  );
  const previouslyBuildNavigationNodes: NavigationNode[] = [];
  const rootNavigationNode = buildNavigationNode(rootNode);
  previouslyBuildNavigationNodes.push(rootNavigationNode);
  const findParentNavigationNode = (node) =>
    previouslyBuildNavigationNodes.find(
      (navigationNode) =>
        navigationNode.path === node.fields.parentPath &&
        navigationNode.hierarchyLevel === node.fields.hierarchyLevel - 1
    );
  data.allMdx.edges.forEach(({ node }: any) => {
    if (isRootNode(node)) {
      return;
    }
    const parentNavigationNode = findParentNavigationNode(node);
    const newNavigationNode = buildNavigationNode(node, parentNavigationNode);
    parentNavigationNode &&
      parentNavigationNode.children.push(newNavigationNode);
    previouslyBuildNavigationNodes.push(newNavigationNode);
  });
  return new NavigationTree(rootNavigationNode);
};

export default useContentNavigationTree;
