import { computed, makeObservable, observable } from "mobx";

class TriggerWordModel {
  public word: string;
  public similarWords: string[];
  public soundFilename: string;

  constructor(word: string, similarWords: string[], soundFilename: string) {
    this.word = word;
    this.similarWords = similarWords;
    this.soundFilename = soundFilename;

    makeObservable(this, {
      word: observable,
      similarWords: observable,
      soundFilename: observable,
      allWordsLowerCase: computed,
    });
  }

  public get allWordsLowerCase() {
    return [
      this.word.toLowerCase(),
      ...this.similarWords.map((word) => word.toLowerCase()),
    ];
  }
}

export default TriggerWordModel;
