import { makeObservable, observable } from "mobx";
import CapacitorStore from "./CapacitorStore";
import ContentBlockStore from "./ContentBlockStore";
import SoundStore from "./SoundStore";
import QuizStore from "./QuizStore";

class RootStore {
  public capacitorStore: CapacitorStore;
  public contentBlockStore: ContentBlockStore;
  public soundStore: SoundStore;
  public quizStore: QuizStore;

  constructor() {
    this.capacitorStore = new CapacitorStore();
    this.contentBlockStore = new ContentBlockStore();
    this.soundStore = new SoundStore();
    this.quizStore = new QuizStore();

    makeObservable(this, {
      capacitorStore: observable,
      contentBlockStore: observable,
      soundStore: observable,
      quizStore: observable,
    });
  }
}

export default RootStore;
