import { action, computed, makeObservable, observable } from "mobx";
import { AbsoluteLinks } from "../constants/links";
import Question, { QuestionBuilder } from "./models/Question";

export const numberOfQuestionsPerSession = 10;

export enum QuizGuiState {
  Home = "Home",
  Question = "Question",
  Answer = "Answer",
  Result = "Result",
}

const isCorrectAnswer = (question: Question, answerIndex: number) =>
  question.correctOptionIndices.indexOf(answerIndex) >= 0;

class QuizStore {
  public allQuestions: Question[] = [];
  public guiState: QuizGuiState = QuizGuiState.Home;
  public questions: Question[] = [];
  public currentQuestionIndex: number = 0;
  public answerIndices: number[] = [];

  constructor() {
    makeObservable(this, {
      allQuestions: observable,
      guiState: observable,
      questions: observable,
      currentQuestionIndex: observable,
      answerIndices: observable,
      currentQuestionNumber: computed,
      currentQuestion: computed,
      currentAnswerIndex: computed,
      currentAnswer: computed,
      isCurrentAnswerCorrect: computed,
      correctlyAnsweredQuestions: computed,
      wronglyAnsweredQuestions: computed,
      numberOfStarsEarned: computed,
      isFinished: computed,
      recommendedTopicPaths: computed,
      reset: action,
      onQuizStarted: action,
      onQuestionAnswered: action,
      next: action,
      restart: action,
    });

    this.allQuestions.push(
      new QuestionBuilder(
        1,
        "Was produziert die Brust der Frau, wenn sie ein Baby bekommt?"
      )
        .setRelatedTopicPath(AbsoluteLinks.FrauKoerperBrueste)
        .setImagePath("illustrations/i_4.3_schwanger.png", false)
        .addOption("Milch", true)
        .addOption("Wasser", false)
        .addOption("Saft", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(2, "Was siehst du auf dem Bild?")
        .setRelatedTopicPath(AbsoluteLinks.FrauKoerperGebaermutter)
        .setImagePath("illustrations/i_periode.png", false)
        .addOption("Brüste", false)
        .addOption("Haare", false)
        .addOption("Gebärmutter", true)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(
        3,
        "Mit was kannst du deine Haare (z. B. an den Beinen) entfernen?"
      )
        .setRelatedTopicPath(AbsoluteLinks.FrauHygiene)
        .setImagePath("pictograms/frau/z_rasurn.png", true)
        .addOption("Rasierer", true)
        .addOption("Schere", false)
        .addOption("Dampf", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(4, "Wie sagt man der Periode auch noch?")
        .setRelatedTopicPath(AbsoluteLinks.FrauPeriode)
        .setImagePath("illustrations/i_periode.png", false)
        .addOption("Baum", false)
        .addOption("Menstruation", true)
        .addOption("Laub", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(5, "Was ist das?")
        .setRelatedTopicPath(AbsoluteLinks.FrauPeriode)
        .setImagePath("illustrations/i_binde.png", false)
        .addOption("Schürze", false)
        .addOption("Nastuch", false)
        .addOption("Binde", true)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(
        6,
        "Welcher Teil der Scheide ist besonders empfindlich?"
      )
        .setRelatedTopicPath(AbsoluteLinks.FrauKoerperScheide)
        .setImagePath("illustrations/i_4.1.2_klitoris.png", false)
        .addOption("Harnröhre", false)
        .addOption("Klitoris", true)
        .addOption("Schamlippe", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(7, "Wann bekommt die Frau die erste Periode?")
        .setRelatedTopicPath(AbsoluteLinks.FrauPubertaet)
        .setImagePath("illustrations/i_erstemens.png", false)
        .addOption("In der Pubertät", true)
        .addOption("Nach dem ersten Sex", false)
        .addOption("Während dem Duschen", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(8, "Wohin streichst du dir das Deo?")
        .setRelatedTopicPath(AbsoluteLinks.MannHygiene)
        .setImagePath("pictograms/mann/z_deo.png", true)
        .addOption("Auf die Füsse", false)
        .addOption("Unter die Achseln", true)
        .addOption("In die Kniekehle", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(9, "Mit was wäschst du am besten die Scheide?")
        .setRelatedTopicPath(AbsoluteLinks.FrauHygiene)
        .setImagePath("pictograms/frau/z_intimpflf.png", true)
        .addOption("Shampoo", false)
        .addOption("Öl", false)
        .addOption("Wasser", true)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(
        10,
        "Während deiner Pubertät wird deine Stimme heiser. Wie nennt man das?"
      )
        .setRelatedTopicPath(AbsoluteLinks.MannPubertaet)
        .setImagePath("illustrations/i_pubertaet6.png", false)
        .addOption("Singstimme", false)
        .addOption("Stimmbruch", true)
        .addOption("Lachen", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(11, "Was ist der Penis?")
        .setRelatedTopicPath(AbsoluteLinks.MannKoerperPenisUndHoden)
        .setImagePath("pictograms/mann/p_penis.png", true)
        .addOption("Geschlechtsorgan", true)
        .addOption("Sinnesorgan", false)
        .addOption("Staatsorgan", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(12, "Was ist das?")
        .setRelatedTopicPath(AbsoluteLinks.MannKoerperPenisUndHoden)
        .setImagePath("pictograms/mann/z_spermien.png", true)
        .addOption("Spermien", true)
        .addOption("Blumen", false)
        .addOption("Kerne", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(13, "Was passiert wenn der Mann sexuell erregt wird?")
        .setRelatedTopicPath(AbsoluteLinks.SexWasIstSexLustUndOrgasmus)
        .setImagePath("illustrations/i_peniserrektion.png", false)
        .addOption("Penis wird steif", true)
        .addOption("Nase wächst", false)
        .addOption("Penis wird kleiner", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(
        14,
        "Was verändert sich beim Mädchen in der Pubertät?"
      )
        .setRelatedTopicPath(AbsoluteLinks.FrauPubertaet)
        .setImagePath("illustrations/i_pubertbrust.png", false)
        .addOption("Haare werden grau", false)
        .addOption("Brüste wachsen", true)
        .addOption("Haut wird faltig", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(15, "Was verändert sich beim Bub in der Pubertät?")
        .setRelatedTopicPath(AbsoluteLinks.MannPubertaet)
        .setImagePath("illustrations/i_pubertaet4.png", false)
        .addOption("Penis wächst", true)
        .addOption("Augen werden schlechter", false)
        .addOption("Haare werden grau", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(16, "Wie oft solltest du am Tag Zähne putzen?")
        .setRelatedTopicPath(AbsoluteLinks.FrauHygiene)
        .setImagePath("pictograms/frau/z_zaehnef.png", true)
        .addOption("3 Mal", true)
        .addOption("nie", false)
        .addOption("6 Mal", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(17, "Wo hat die Frau Schamhaare?")
        .setRelatedTopicPath(AbsoluteLinks.FrauKoerperKoerperbehaarung)
        .setImagePath("pictograms/frau/p_fraukoerper.png", true)
        .addOption("Auf den äusseren Schamlippen", true)
        .addOption("Im Gesicht", false)
        .addOption("An den Beinen", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(
        18,
        "Wann kommt das Mädchen ungefähr in die Pubertät?"
      )
        .setRelatedTopicPath(AbsoluteLinks.FrauPubertaet)
        .setImagePath("illustrations/i_pubertaetf1.png", false)
        .addOption("30 Jahre", false)
        .addOption("10 Jahre", true)
        .addOption("3 Jahre", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(19, "Was bekommt der Mann beim Orgasmus?")
        .setRelatedTopicPath(AbsoluteLinks.SexWasIstSexLustUndOrgasmus)
        .setImagePath("illustrations/i_4.1.2_orgasmusmann.png", false)
        .addOption("Längere Haare", false)
        .addOption("Kalt", false)
        .addOption("Samenerguss", true)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(
        20,
        "Was ist ein anderes Wort für Selbstbefriedigung?"
      )
        .setRelatedTopicPath(AbsoluteLinks.SexWasIstSexSelbstbefriedigung)
        .setImagePath("pictograms/sex/z_solosex.png", true)
        .addOption("Solosex", true)
        .addOption("Orgasmus", false)
        .addOption("Sperma", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(
        21,
        "Wie nennt man es, wenn eine Frau eine Frau liebt?"
      )
        .setRelatedTopicPath(AbsoluteLinks.SexSexuelleOrientierung)
        .setImagePath("illustrations/i_4.1.5_lesbisch.png", false)
        .addOption("Homosexuell", true)
        .addOption("Bisexuell", false)
        .addOption("Heterosexuell", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(
        22,
        "Wie nennt man es, wenn eine Mann ein Mann liebt?"
      )
        .setRelatedTopicPath(AbsoluteLinks.SexSexuelleOrientierung)
        .setImagePath("illustrations/i_4.1.5_schwul.png", false)
        .addOption("Homosexuell", true)
        .addOption("Bisexuell", false)
        .addOption("Heterosexuell", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(
        23,
        "Wie nennt man es, wenn man beide Geschlechter liebt?"
      )
        .setRelatedTopicPath(AbsoluteLinks.SexSexuelleOrientierung)
        .setImagePath("illustrations/i_4.1.5_vorlieben.png", false)
        .addOption("Homosexuell", false)
        .addOption("Bisexuell", true)
        .addOption("Heterosexuell", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(
        24,
        "Wie nennt man es, wenn eine Frau einen Mann liebt?"
      )
        .setRelatedTopicPath(AbsoluteLinks.SexSexuelleOrientierung)
        .setImagePath("illustrations/i_4.1.5_hetero.png", false)
        .addOption("Homosexuell", false)
        .addOption("Bisexuell", false)
        .addOption("Heterosexuell", true)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(25, "Was ist das?")
        .setRelatedTopicPath(AbsoluteLinks.SexVerhuetung)
        .setImagePath("illustrations/i_4.2_kondom.png", false)
        .addOption("Kondom", true)
        .addOption("Spirale", false)
        .addOption("Pille", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(26, "Was ist das?")
        .setRelatedTopicPath(AbsoluteLinks.SexVerhuetung)
        .setImagePath("illustrations/i_4.2_pille.png", false)
        .addOption("Kondom", false)
        .addOption("Spirale", false)
        .addOption("Pille", true)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(27, "Für was braucht man ein Kondom?")
        .setRelatedTopicPath(AbsoluteLinks.SexVerhuetung)
        .setImagePath("illustrations/i_4.2_kondom.png", false)
        .addOption("Verhütung", true)
        .addOption("Wärme", false)
        .addOption("Hut", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(28, "Davon wird eine Frau schwanger?")
        .setRelatedTopicPath(AbsoluteLinks.SexSchwangerschaft)
        .setImagePath("illustrations/i_4.3_schwanger.png", false)
        .addOption("Sex", true)
        .addOption("Küssen", false)
        .addOption("Verliebte Blicke", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(
        29,
        "Was sind Bilder und Filme, die Leute beim Sex zeigen?"
      )
        .setRelatedTopicPath(AbsoluteLinks.SexPornografie)
        .setImagePath("illustrations/i_4.4_haendy.png", false)
        .addOption("Blockbuster", false)
        .addOption("Pornografie", true)
        .addOption("Ferienfotos", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(30, "Wie nennt man diese Sexstellung?")
        .setRelatedTopicPath(AbsoluteLinks.SexWasIstSexSexpraktiken)
        .setImagePath("illustrations/i_4.1.3_missionar.png", false)
        .addOption("Missionarsstellung", true)
        .addOption("Hündchenstellung", false)
        .addOption("Reiterin-Stellung", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(
        31,
        "Darf jemand mit mir Sex haben, wenn ich das nicht will?"
      )
        .setRelatedTopicPath(AbsoluteLinks.SexSexuelleGewalt)
        .setImagePath("illustrations/i_4.6_nein.png", false)
        .addOption("NEIN", true)
        .addOption("NEIN", true)
        .addOption("NEIN", true)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(32, "Was passiert wenn die Frau sexuell erregt ist?")
        .setRelatedTopicPath(AbsoluteLinks.SexWasIstSexLustUndOrgasmus)
        .setImagePath("illustrations/i_4.1.2_scheide.png", false)
        .addOption("Hände werden feucht", false)
        .addOption("Nase läuft", false)
        .addOption("Die Scheide wird feucht", true)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(33, "Das schützt gegen Geschlechtskrankheiten")
        .setRelatedTopicPath(AbsoluteLinks.SexVerhuetung)
        .setImagePath("illustrations/i_4.2_kondom.png", false)
        .addOption("Stäbchen", false)
        .addOption("Kondom", true)
        .addOption("Pille", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(34, "Welches Verhütungsmittel ist das?")
        .setRelatedTopicPath(AbsoluteLinks.SexVerhuetung)
        .setImagePath("illustrations/i_4.2_hormonpflaster.png", false)
        .addOption("Kondom", false)
        .addOption("Pille", false)
        .addOption("Hormonpflaster", true)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(35, "Welches Verhütungsmittel ist das?")
        .setRelatedTopicPath(AbsoluteLinks.SexVerhuetung)
        .setImagePath("illustrations/i_4.2_pille.png", false)
        .addOption("Kondom", false)
        .addOption("Pille", true)
        .addOption("Hormonpflaster", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(36, "Wie lange dauert eine Schwangerschaft ungefähr?")
        .setRelatedTopicPath(AbsoluteLinks.SexSchwangerschaft)
        .setImagePath("illustrations/i_4.3_bauch_immer_runder.png", false)
        .addOption("1 Monat", false)
        .addOption("2 Monate", false)
        .addOption("9 Monate", true)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(37, "Wie testet die Frau, ob sie schwanger ist?")
        .setRelatedTopicPath(AbsoluteLinks.SexSchwangerschaft)
        .setImagePath("pictograms/sex/z_schwangerschaftstest.png", true)
        .addOption("Alkoholtest", false)
        .addOption("Schwangerschaftstest", true)
        .addOption("Warentest", false)
        .build()
    );

    this.allQuestions.push(
      new QuestionBuilder(
        38,
        "Was ist ein Anzeichen, dass eine Frau schwanger ist?"
      )
        .setRelatedTopicPath(AbsoluteLinks.SexSchwangerschaft)
        .setImagePath("illustrations/i_4.3_schwanger.png", false)
        .addOption("Sie hat keine Periode mehr", true)
        .addOption("Sie macht mehr Sport", false)
        .addOption("Sie bastelt lieber", false)
        .build()
    );

    this.reset();
  }

  public get currentQuestionNumber() {
    return this.currentQuestionIndex + 1;
  }

  public get currentQuestion() {
    return this.questions[this.currentQuestionIndex];
  }

  public get currentAnswerIndex() {
    return this.answerIndices[this.currentQuestionIndex];
  }

  public get currentAnswer() {
    return this.currentQuestion.options[this.currentAnswerIndex];
  }

  public get isCurrentAnswerCorrect() {
    return isCorrectAnswer(this.currentQuestion, this.currentAnswerIndex);
  }

  public get correctlyAnsweredQuestions() {
    const correctlyAnsweredQuestions: Question[] = [];
    this.answerIndices.forEach((answerIndex, questionIndex) => {
      const question = this.questions[questionIndex];
      if (isCorrectAnswer(question, answerIndex)) {
        correctlyAnsweredQuestions.push(question);
      }
    });
    return correctlyAnsweredQuestions;
  }

  public get wronglyAnsweredQuestions() {
    const wronglyAnsweredQuestions: Question[] = [];
    this.answerIndices.forEach((answerIndex, questionIndex) => {
      const question = this.questions[questionIndex];
      if (!isCorrectAnswer(question, answerIndex)) {
        wronglyAnsweredQuestions.push(question);
      }
    });
    return wronglyAnsweredQuestions;
  }

  public get numberOfStarsEarned() {
    return (
      Math.floor(
        (this.correctlyAnsweredQuestions.length / numberOfQuestionsPerSession) *
          4
      ) + 1
    );
  }

  public get isFinished() {
    return this.currentQuestionIndex === numberOfQuestionsPerSession;
  }

  public get recommendedTopicPaths(): string[] {
    interface TopicPath {
      topicPath: string;
      occurrences: number;
    }

    const recommendedTopicPaths: TopicPath[] = [];
    this.wronglyAnsweredQuestions.forEach((question) => {
      if (!question.relatedTopicPath) {
        return;
      }
      const existingTopicPath = recommendedTopicPaths.find(
        (topicPath) => topicPath.topicPath === question.relatedTopicPath
      );
      if (!!existingTopicPath) {
        existingTopicPath.occurrences++;
      } else {
        recommendedTopicPaths.push({
          topicPath: question.relatedTopicPath,
          occurrences: 1,
        });
      }
    });
    const sortedTopicPaths = recommendedTopicPaths.sort((a, b) =>
      a.occurrences < b.occurrences ? 1 : -1
    );
    return sortedTopicPaths.slice(0, 2).map((topicPath) => topicPath.topicPath);
  }

  public reset() {
    this.guiState = QuizGuiState.Home;
    this.questions = this.allQuestions
      .slice(0)
      .sort((a, b) => (Math.random() >= 0.5 ? 1 : -1))
      .slice(0, numberOfQuestionsPerSession);
    this.currentQuestionIndex = 0;
    this.answerIndices = [];
  }

  public onQuizStarted() {
    this.guiState = QuizGuiState.Question;
  }

  public onQuestionAnswered(answerOptionIndex: number) {
    this.answerIndices.push(answerOptionIndex);
    this.guiState = QuizGuiState.Answer;
  }

  public next() {
    if (this.currentQuestionIndex + 1 < numberOfQuestionsPerSession) {
      this.currentQuestionIndex++;
      this.guiState = QuizGuiState.Question;
    } else {
      this.guiState = QuizGuiState.Result;
    }
  }

  public restart() {
    this.reset();
    this.onQuizStarted();
  }
}

export default QuizStore;
