import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import icon from "../images/icon.png";

interface SeoProps {
  relativePageUrl: string;
  pageTitle?: string;
  description?: string;
  relativeImageUrl?: string;
}

const Seo: React.FunctionComponent<SeoProps> = (props) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  const title = props.pageTitle
    ? `${props.pageTitle} - ${siteMetadata.title}`
    : siteMetadata.title;
  const description = props.description || siteMetadata.description;
  const url = `${siteMetadata.siteUrl}${props.relativePageUrl}`;
  const logoImageUrl = `${siteMetadata.siteUrl}${
    props.relativeImageUrl || icon
  }`;

  return (
    <>
      <html lang="de" />
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:site_name" content="Klar und Einfach" />
      <meta property="og:image" content={logoImageUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={logoImageUrl} />
    </>
  );
};

export default Seo;
