import { makeObservable, observable, computed } from "mobx";

export class QuestionBuilder {
  public id: number;
  public question: string;
  public relatedTopicPath?: string;
  public imagePath?: string;
  public isPictogramImage?: boolean;
  public options: string[];
  public correctOptionIndices: number[];

  constructor(id: number, question: string) {
    this.id = id;
    this.question = question;
    this.options = [];
    this.correctOptionIndices = [];
  }

  public setRelatedTopicPath = (relatedTopicPath: string): QuestionBuilder => {
    this.relatedTopicPath = relatedTopicPath;
    return this;
  };

  public setImagePath = (
    imagePath: string,
    isPictogramImage: boolean
  ): QuestionBuilder => {
    this.imagePath = imagePath;
    this.isPictogramImage = isPictogramImage;
    return this;
  };

  public addOption = (option: string, isCorrect: boolean): QuestionBuilder => {
    isCorrect && this.correctOptionIndices.push(this.options.length);
    this.options.push(option);
    return this;
  };

  public build = () => new Question(this);
}

class Question {
  public id: number;
  public question: string;
  public relatedTopicPath?: string;
  public imagePath?: string;
  public isPictogramImage?: boolean;
  public options: string[];
  public correctOptionIndices: number[];

  constructor(builder: QuestionBuilder) {
    this.id = builder.id;
    this.question = builder.question;
    this.relatedTopicPath = builder.relatedTopicPath;
    this.imagePath = builder.imagePath;
    this.isPictogramImage = builder.isPictogramImage;
    this.options = builder.options;
    this.correctOptionIndices = builder.correctOptionIndices;

    makeObservable(this, {
      id: observable,
      question: observable,
      relatedTopicPath: observable,
      imagePath: observable,
      isPictogramImage: observable,
      options: observable,
      correctOptionIndices: observable,
      correctOptions: computed,
    });
  }

  public get correctOptions() {
    return this.correctOptionIndices.map((index) => this.options[index]);
  }
}

export default Question;
