import * as React from "react";
import { useStore } from "../components/StoreProvider";
import { useObserver } from "mobx-react-lite";
import SoundStore from "../stores/SoundStore";

export interface SoundControls {
  soundFilePath: string;
  playSound: () => void;
  stopSound: () => void;
  isSoundReady: boolean;
  isSoundPlaying: boolean;
}

const loadSounds = (soundStore: SoundStore, soundFilePaths: string[]) => {
  soundFilePaths.forEach((soundFilePath) => {
    soundStore.loadSound(soundFilePath);
  });
};

const unloadSounds = (soundStore: SoundStore, soundFilePaths: string[]) => {
  soundFilePaths.forEach((soundFilePath) => {
    soundStore.unloadSound(soundFilePath);
  });
};

const buildSoundControlsMap = (
  soundStore: SoundStore,
  soundFilePaths: string[]
) => {
  const relevantSoundStates = soundStore.soundStates.filter(
    (soundState) => soundFilePaths.indexOf(soundState.soundFilePath) !== -1
  );
  const map = new Map<string, SoundControls>();
  relevantSoundStates.forEach((soundState) => {
    map.set(soundState.soundFilePath, {
      soundFilePath: soundState.soundFilePath,
      playSound: () => soundStore.playSound(soundState.soundFilePath),
      stopSound: () => soundStore.stopSound(soundState.soundFilePath),
      isSoundReady: soundState.isSoundReady,
      isSoundPlaying: soundState.isSoundPlaying,
    });
  });
  return map;
};

const useSound = (soundFilePaths: string[]): Map<string, SoundControls> => {
  const rootStore = useStore();

  React.useEffect(() => {
    loadSounds(rootStore.soundStore, soundFilePaths);
    return () => unloadSounds(rootStore.soundStore, soundFilePaths);
  }, [rootStore.soundStore, soundFilePaths]);

  const soundControls = React.useRef<Map<string, SoundControls>>(new Map());

  useObserver(() => {
    soundControls.current = buildSoundControlsMap(
      rootStore.soundStore,
      soundFilePaths
    );
  });

  return soundControls.current;
};

export default useSound;
