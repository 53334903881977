import isMobile from "./isMobile";

export const filterNotInNavbarMdxNodes = (array: any[]) =>
  array.filter(
    ({
      node: {
        frontmatter: { isNotInNavbar },
      },
    }) => !isNotInNavbar
  );

export default (array: any[]) =>
  array.filter(
    ({
      node: {
        frontmatter: { isInNavbarOnMobileOnly },
      },
    }) => !(isInNavbarOnMobileOnly && !isMobile)
  );
