import { graphql, useStaticQuery } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";

const usePictogramImage = (
  pathRelativeToImagesDirectory: string
): IGatsbyImageData => {
  const data = useStaticQuery(graphql`
    query PictogramImageQuery {
      allImageSharp {
        edges {
          node {
            gatsbyImageData(width: 140, layout: FIXED, quality: 100)
            parent {
              ... on File {
                relativePath
              }
            }
          }
        }
      }
    }
  `);
  const matchingEdge = data.allImageSharp.edges.find(({ node }) => {
    return node.parent.relativePath === pathRelativeToImagesDirectory;
  });
  return matchingEdge?.node.gatsbyImageData;
};

export default usePictogramImage;
