import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const Back = () => {
  return (
    <StaticImage
      src="../images/icons/back.png"
      alt="Zurück"
      layout="fixed"
      placeholder="none"
      width={24}
      height={24}
    />
  );
};

export const BurgerMenu = () => {
  return (
    <StaticImage
      src="../images/icons/burger.png"
      alt="Hauptmenü"
      layout="fixed"
      placeholder="none"
      width={24}
      height={24}
    />
  );
};

export const Home = () => {
  return (
    <StaticImage
      src="../images/icons/home.png"
      alt="Zur Startseite"
      layout="fixed"
      placeholder="none"
      width={24}
      height={24}
    />
  );
};

export const Top = () => {
  return (
    <StaticImage
      src="../images/icons/top.png"
      alt="Nach oben"
      layout="fixed"
      placeholder="none"
      width={24}
      height={24}
    />
  );
};

export const SpeakerOnWhite = () => {
  return (
    <StaticImage
      src="../images/icons/audio/Lautsprecher.png"
      alt="Sprachwiedergabe stoppen"
      layout="fixed"
      placeholder="none"
      width={24}
      height={24}
    />
  );
};

export const SpeakerOffWhite = () => {
  return (
    <StaticImage
      src="../images/icons/audio/Lautsprecherohne.png"
      alt="Sprachwiedergabe starten"
      layout="fixed"
      placeholder="none"
      width={24}
      height={24}
    />
  );
};

export const SpeakerOnBlack = () => {
  return (
    <StaticImage
      src="../images/icons/audio/sLautsprecher.png"
      alt="Sprachwiedergabe stoppen"
      layout="fixed"
      placeholder="none"
      width={24}
      height={24}
    />
  );
};

export const SpeakerOffBlack = () => {
  return (
    <StaticImage
      src="../images/icons/audio/sLautsprecherohne.png"
      alt="Sprachwiedergabe starten"
      layout="fixed"
      placeholder="none"
      width={24}
      height={24}
    />
  );
};
