import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import StoreProvider from "../components/StoreProvider";
import AppInitializer from "../components/AppInitializer";

export const wrapRootElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => (
  <StoreProvider>
    <AppInitializer />
    {element}
  </StoreProvider>
);
