import * as React from "react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import { tabletAndBigger } from "../layouts/MediaQueries";
import usePictogramImage from "../hooks/usePictogramImage";

interface ContentElementProps extends React.HTMLAttributes<HTMLDivElement> {
  pictogram?: string;
  pictogramAltText?: string;
  isLink?: boolean;
  contentContainerClassName?: string;
}

const PictogramContainer = styled.div<{ isLink?: boolean }>`
  flex-shrink: 0;
  margin-right: 20px;
  display: flex;

  width: ${(props) => (props.isLink ? 60 : 140)}px;
  ${tabletAndBigger} {
    width: 140px;
  }
`;

const Pictogram = styled(GatsbyImage)<{ isLink?: boolean }>`
  max-width: ${(props) => (props.isLink ? 60 : 140)}px;
  max-height: ${(props) => (props.isLink ? 60 : 140)}px;
`;

const Container = styled.div<{ isLink?: boolean }>`
  display: flex;
  align-items: ${(props) => (props.isLink ? "center" : "flex-start")};
  flex-direction: ${(props) => (props.isLink ? "row" : "column")};
  padding-left: 20px;
  padding-right: 20px;

  ${tabletAndBigger} {
    align-items: ${(props) => (props.isLink ? "center" : "flex-start")};
    flex-direction: row;
    padding-left: 40px;
    padding-right: 120px;
  }
`;

const ContentContainer = styled.div<{ isLink?: boolean }>`
  ${(props) => props.isLink && "align-self: center;"}
  width: 100%;
  max-width: 100%;
`;

const ContentElement: React.FunctionComponent<ContentElementProps> = (
  props
) => {
  const pictogramImage = usePictogramImage(props.pictogram || "");
  const pictogramContainer = (
    <PictogramContainer isLink={props.isLink}>
      {props.pictogram && (
        <Pictogram
          isLink={props.isLink}
          image={pictogramImage}
          alt={props.pictogramAltText || ""}
        />
      )}
    </PictogramContainer>
  );
  return (
    <Container isLink={props.isLink} {...props}>
      {pictogramContainer}
      <ContentContainer className={props.contentContainerClassName}>
        {props.children}
      </ContentContainer>
    </Container>
  );
};

export default ContentElement;
