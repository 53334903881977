import * as React from "react";
import styled from "@emotion/styled";
import tinycolor from "tinycolor2";
import { ColorShade } from "../types/color-theme";
import { ColorThemeContext } from "../layouts/DefaultLayout";
import {
  karlUndKlaraContentBlockColors,
  karlUndKlaraIllustrationBackgroundColor,
} from "../constants/color-themes";

interface FloorColors {
  floorColorRgba: string;
  backgroundColorRgba: string;
}

interface FloorContainerProps extends FloorColors {
  floorHeightPercentage?: number;
}

const FloorContainer = styled.div<FloorContainerProps>`
  background: linear-gradient(
    0deg,
    ${(props) =>
      `${props.floorColorRgba} ${props.floorHeightPercentage || 10}%`},
    ${(props) =>
      `${props.backgroundColorRgba} ${props.floorHeightPercentage || 10}%`},
    ${(props) => props.backgroundColorRgba} 100%
  );
`;

interface ImageFloorProps {
  backgroundColor?: string;
  floorColor?: string;
  colorShade?: ColorShade;
  floorHeightPercentage?: number;
}

const toRgba = (colorHex: string, alpha: number) => {
  const color = tinycolor(colorHex);
  color.setAlpha(alpha);
  return color.toRgbString();
};

const ImageFloor: React.FunctionComponent<ImageFloorProps> = (props) => {
  const colorTheme = React.useContext(ColorThemeContext);

  const evaluateFloorColors = React.useCallback((): FloorColors => {
    if (props.colorShade) {
      return {
        floorColorRgba: toRgba(
          colorTheme.colorsByShade[props.colorShade].backgroundColor,
          1
        ),
        backgroundColorRgba: toRgba("#FFFFFF", 1),
      };
    }
    return {
      floorColorRgba: toRgba(props.floorColor || "#000000", 1),
      backgroundColorRgba: toRgba(props.backgroundColor || "#FFFFFF", 1),
    };
  }, [props.colorShade, colorTheme, props.floorColor, props.backgroundColor]);

  const [floorColors, setFloorColors] =
    React.useState<FloorColors>(evaluateFloorColors);

  React.useEffect(
    () => setFloorColors(evaluateFloorColors()),
    [
      colorTheme,
      props.colorShade,
      props.floorColor,
      props.backgroundColor,
      evaluateFloorColors,
    ]
  );

  return (
    <FloorContainer
      floorColorRgba={floorColors.floorColorRgba}
      backgroundColorRgba={floorColors.backgroundColorRgba}
      floorHeightPercentage={props.floorHeightPercentage}
    >
      {props.children}
    </FloorContainer>
  );
};

export const KarlUndKlaraImageFloor: React.FunctionComponent = ({
  children,
  ...otherProps
}) => (
  <ImageFloor
    backgroundColor={karlUndKlaraIllustrationBackgroundColor}
    floorColor={karlUndKlaraContentBlockColors.backgroundColor}
    {...otherProps}
  >
    {children}
  </ImageFloor>
);

export default ImageFloor;
