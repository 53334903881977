import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import useIllustrationImage from "../hooks/useIllustrationImage";
import { illustrationMaxHeight } from "../constants/sizes";

const Image = styled(GatsbyImage)`
  max-height: ${illustrationMaxHeight};
`;

interface IllustrationProps {
  src: string;
  alt: string;
}

const Illustration: React.FunctionComponent<IllustrationProps> = (props) => {
  const illustrationImage = useIllustrationImage(props.src);
  return (
    <Image objectFit="contain" image={illustrationImage} alt={props.alt} />
  );
};

export default Illustration;
