import * as React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const GatsbyLink: React.FunctionComponent<
  React.ComponentProps<typeof AnchorLink>
> = (props) => {
  return (
    <AnchorLink {...props} stripHash={true}>
      {props.children}
    </AnchorLink>
  );
};

export default GatsbyLink;
