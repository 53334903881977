import * as React from "react";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";
import filterMobileOnlyMdxNodes, {
  filterNotInNavbarMdxNodes,
} from "../../utils/filterMobileOnlyMdxNodes";
import DesktopBarLinkItem from "./DesktopBarLinkItem";
import { isLinkItemHighlighted } from "./index";

const Container = styled.ul`
  position: relative;
  padding: 0;
  margin: 0;
  height: 200px;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`;

interface DesktopBarProps {}

const DesktopBar: React.FunctionComponent<DesktopBarProps> = () => {
  const location = useLocation();

  const data = useStaticQuery(graphql`
    {
      allMdx(
        filter: { fields: { hierarchyLevel: { lte: 1 } } }
        sort: [
          { fields: { hierarchyLevel: DESC } }
          { fields: { sortKey: ASC } }
        ]
      ) {
        edges {
          node {
            frontmatter {
              key
              title
              isNotInNavbar
              isInNavbarOnMobileOnly
              icon {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 100)
                }
              }
              iconActive {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 100)
                }
              }
            }
            fields {
              path
            }
          }
        }
      }
    }
  `);
  const filteredNodes = filterNotInNavbarMdxNodes(
    filterMobileOnlyMdxNodes(data.allMdx.edges)
  );
  const desktopBarLinkItems = filteredNodes.map(({ node }: any) => (
    <li key={node.frontmatter.key}>
      <DesktopBarLinkItem
        to={node.fields.path}
        isActive={isLinkItemHighlighted(location.pathname, node.fields.path)}
        icon={node.frontmatter.icon.childImageSharp.gatsbyImageData}
        activeIcon={
          node.frontmatter.iconActive?.childImageSharp?.gatsbyImageData
        }
        text={node.frontmatter.title}
      />
    </li>
  ));
  return <Container>{desktopBarLinkItems}</Container>;
};

export default DesktopBar;
