import * as React from "react";
import RootStore from "../stores/RootStore";

const rootStore = new RootStore();

const storeContext = React.createContext<RootStore>(rootStore);

export const useStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    throw new Error("You have forgot to use StoreProvider.");
  }

  return store;
};

const StoreProvider: React.FunctionComponent = (props) => {
  return (
    <storeContext.Provider value={rootStore}>
      {props.children}
    </storeContext.Provider>
  );
};

export default StoreProvider;
