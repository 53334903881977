import { action, makeObservable, observable } from "mobx";
import { App, StateChangeListener } from "@capacitor/app";
import { Device, DeviceInfo } from "@capacitor/device";
import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar } from "@capacitor/status-bar";
import {
  PushNotifications,
  PushNotificationSchema,
  ActionPerformed,
} from "@capacitor/push-notifications";
import { FCM } from "@capacitor-community/fcm";
import isMobile from "../utils/isMobile";
import { Capacitor } from "@capacitor/core";

class CapacitorStore {
  public platform?: DeviceInfo["platform"];
  public deviceId?: string;
  public pushNotificationToken?: string;

  constructor() {
    this.platform = undefined;
    this.deviceId = undefined;
    this.pushNotificationToken = undefined;

    makeObservable(this, {
      platform: observable,
      deviceId: observable,
      pushNotificationToken: observable,
      synchronizePlatformInformation: action,
      registerAppStateChangeListener: action,
      removeAllAppListeners: action,
      registerForPushNotifications: action,
      createOrUpdateUser: action,
      addPushNotificationRegisteredListener: action,
      addPushNotificationReceivedListener: action,
      addPushNotificationActionPerformedListener: action,
      removeAllPushNotificationListeners: action,
      changeStatusBarColor: action,
      hideStatusBar: action,
      hideSplashScreen: action,
    });
  }

  public synchronizePlatformInformation = async () => {
    this.platform = (await Device.getInfo()).platform;
    this.deviceId = (await Device.getId()).uuid;
  };

  public registerAppStateChangeListener = (listener: StateChangeListener) => {
    App.addListener("appStateChange", listener);
  };

  public removeAllAppListeners = () => App.removeAllListeners();

  public registerForPushNotifications = async () => {
    let permissionStatus = await PushNotifications.checkPermissions();
    if (permissionStatus.receive === "prompt") {
      permissionStatus = await PushNotifications.requestPermissions();
    }
    if (permissionStatus.receive === "granted") {
      await PushNotifications.register();
    }
  };

  public createOrUpdateUser = async () => {
    const requestUrl = `${process.env.GATSBY_API_URL}/lovestory/users`;
    await fetch(requestUrl, {
      method: "POST",
      body: JSON.stringify({
        userId: this.deviceId,
        pid: this.pushNotificationToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  public addPushNotificationRegisteredListener = async (
    callback: (token: string) => void
  ) => {
    await PushNotifications.addListener("registration", async (token) => {
      if (this.pushNotificationToken) {
        return;
      }
      if (Capacitor.getPlatform() === "ios") {
        const { token: fcm_token } = await FCM.getToken();
        this.pushNotificationToken = fcm_token;
      } else {
        this.pushNotificationToken = token.value;
      }
      callback(this.pushNotificationToken);
    });
  };

  public addPushNotificationReceivedListener = async (
    callback: (notification: PushNotificationSchema) => void
  ) => {
    await PushNotifications.addListener("pushNotificationReceived", callback);
  };

  public addPushNotificationActionPerformedListener = async (
    callback: (notification: ActionPerformed) => void
  ) => {
    PushNotifications.addListener("pushNotificationActionPerformed", callback);
  };

  public removeAllPushNotificationListeners = async () => {
    await PushNotifications.removeAllListeners();
  };

  public changeStatusBarColor = async (newColorHex: string) => {
    if (isMobile) {
      return StatusBar.setBackgroundColor({
        color: newColorHex,
      });
    }
  };

  public hideStatusBar = async () => {
    if (isMobile) {
      return StatusBar.hide();
    }
  };

  public hideSplashScreen = async () => {
    if (isMobile) {
      return SplashScreen.hide();
    }
  };
}

export default CapacitorStore;
