import * as React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import { NavigationBarLinkItemProps } from "./index";
import { ColorThemeContext } from "../../layouts/DefaultLayout";
import GatsbyLink from "../GatsbyLink";

const LinkElement = styled(GatsbyLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 110px;
`;

const LinkIcon = styled(GatsbyImage)`
  max-width: 100px;
`;

const LinkText = styled.span`
  margin-top: 4px;
`;

const DesktopBarLinkItem: React.FunctionComponent<
  NavigationBarLinkItemProps
> = ({ isActive, icon, activeIcon, text, children, ...gatsbyLinkProps }) => {
  const colorTheme = React.useContext(ColorThemeContext);
  return (
    <LinkElement
      css={css`
        background-color: ${isActive && !activeIcon
          ? colorTheme.colors[2]
          : "transparent"};
      `}
      {...gatsbyLinkProps}
    >
      <LinkIcon image={isActive && activeIcon ? activeIcon : icon} alt={text} />
      <LinkText>{text}</LinkText>
      {children}
    </LinkElement>
  );
};

export default DesktopBarLinkItem;
