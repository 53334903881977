import ColorTheme, { ContentBlockColors } from "../types/color-theme";

interface ColorThemesInterface {
  [key: string]: ColorTheme;
}

const ColorThemes: ColorThemesInterface = {
  RED: new ColorTheme(
    "#7b0431",
    "#e8b7bf",
    "#f3d3dd",
    "#fdeef4",
    "#6d0328",
    "#893145",
    "#b25469",
    "#99294b",
    "#f8e4ea",
    "#f0c2ca",
    "#f8e4ea"
  ),
  BLUE: new ColorTheme(
    "#002c65",
    "#b8c3e2",
    "#dbe1f1",
    "#f3f5fa",
    "#013a7a",
    "#3d5b93",
    "#647fb3",
    "#3161a7",
    "#dee4f2",
    "#bfc9e5",
    "#dee4f2"
  ),
  PURPLE: new ColorTheme(
    "#3c0257",
    "#c7bcd7",
    "#e1d9eb",
    "#f1eff5",
    "#3c0257",
    "#6b5285",
    "#78638c",
    "#5b3c75",
    "#e1d9eb",
    "#c7bcd7",
    "#e1d9eb"
  ),
  GREEN: new ColorTheme(
    "#00442a",
    "#b2d2c3",
    "#d1e6db",
    "#ebf4ef",
    "#00442a",
    "#1b624a",
    "#2d7c5f",
    "#00543b",
    "#d5e9df",
    "#acd5c2",
    "#d5e9df"
  ),
  YELLOW: new ColorTheme(
    "#705100",
    "#d3c595",
    "#eae2c5",
    "#f9f8e4",
    "#7d5d00",
    "#927016",
    "#b09344",
    "#927016",
    "#eee8d0",
    "#e1d2a8",
    "#eee8d0"
  ),
  ORANGE: new ColorTheme(
    "#a2330e",
    "#ecac95",
    "#f3d0c1",
    "#faeadf",
    "#cc431b",
    "#d5683c",
    "#dc7347",
    "#cc431b",
    "#f8ddce",
    "#efb49e",
    "#f8ddce"
  ),
  BLACK: new ColorTheme(
    "#231f20",
    "#a8a9ad",
    "#d1d2d4",
    "#e6e7e9",
    "#262425",
    "#545455",
    "#909094",
    "#424243",
    "#c9cbcd",
    "#aaabaf",
    "#e1e2e5"
  ),
};

export default ColorThemes;

export const karlUndKlaraContentBlockColors: ContentBlockColors = {
  backgroundColor: "#2bb572",
  textColor: "#FFFFFF",
  textHighlightColor: "#23915a",
};
export const karlUndKlaraIllustrationBackgroundColor = "#fbdbe0";

export const defaultColorTheme = ColorThemes["BLACK"];
