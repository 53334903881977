import { makeObservable, observable } from "mobx";
import { Howl } from "howler";

export interface SoundState {
  soundFilePath: string;
  isSoundReady: boolean;
  isSoundPlaying: boolean;
}

class SoundStore {
  private soundFiles: Map<string, Howl> = new Map();
  public soundStates: SoundState[] = [];

  constructor() {
    makeObservable(this, {
      soundStates: observable,
    });
  }

  public loadSound(soundFilePath: string) {
    const isAlreadyLoaded = this.soundFiles.has(soundFilePath);
    if (isAlreadyLoaded) {
      return;
    }
    this.soundStates.push({
      soundFilePath,
      isSoundReady: false,
      isSoundPlaying: false,
    });
    this.soundFiles.set(soundFilePath, this.initializeHowl(soundFilePath));
  }

  private initializeHowl(soundFilePath: string) {
    const howl = new Howl({ src: [soundFilePath] });
    howl.on("load", () => {
      this.getSoundStateBySoundFilePath(soundFilePath).isSoundReady = true;
    });
    howl.on("play", () => {
      this.getSoundStateBySoundFilePath(soundFilePath).isSoundPlaying = true;
    });
    howl.on("end", () => {
      this.getSoundStateBySoundFilePath(soundFilePath).isSoundPlaying = false;
    });
    return howl;
  }

  public unloadSound(soundFilePath: string) {
    if (!this.soundFiles.has(soundFilePath)) {
      return;
    }
    this.getSoundFileByPath(soundFilePath).stop();
    this.getSoundFileByPath(soundFilePath).unload();
    this.soundFiles.delete(soundFilePath);
    this.soundStates.splice(
      this.soundStates.findIndex(
        (sound) => sound.soundFilePath === soundFilePath
      ),
      1
    );
  }

  public playSound(soundFilePath: string) {
    this.stopAllSounds();
    this.getSoundFileByPath(soundFilePath).play();
  }

  public stopSound(soundFilePath: string) {
    this.getSoundFileByPath(soundFilePath).stop();
    this.getSoundStateBySoundFilePath(soundFilePath).isSoundPlaying = false;
  }

  public getSoundStateBySoundFilePath(soundFilePath: string) {
    const soundState = this.soundStates.find(
      (sound) => sound.soundFilePath === soundFilePath
    );
    if (!soundState) {
      throw new Error(
        `Could not find sound state for sound with path: ${soundFilePath}`
      );
    }
    return soundState;
  }

  private getSoundFileByPath(soundFilePath: string) {
    const soundFile = this.soundFiles.get(soundFilePath);
    if (!soundFile) {
      throw new Error(`Could not find sound file with path ${soundFilePath}`);
    }
    return soundFile;
  }

  public stopAllSounds() {
    this.soundFiles.forEach((howl, soundFilePath) =>
      this.stopSound(soundFilePath)
    );
  }
}

export default SoundStore;
